p-table {
  display: block;
  background: var(--white);
  flex: 1;
  &.admin-table {
    margin-top: 0;
  }
  .p-datatable {
    display: block;
    font-size: var(--sm-fontsize);

    .icon-loading {
      color: var(--primary);
      font-size: 48px;
    }

    table {
      .empty-message {
        font-size: var(--base-fontsize);
      }

      > .p-datatable-thead {
        > tr {
          > th {
            border: 1px solid var(--light-color-2);
            border-width: 0 0 1px 0;
            color: var(--light-color);
            padding: 6px 12px;
            background-color: #eeeeee;
            font-size: 13px;
            line-height: 20px;
            &.p-sortable-column {
              &:focus {
                box-shadow: none;
              }
              .p-icon-wrapper {
                margin-left: 2px;
                color: #909090;
                display: inline-flex;
                font-weight: 400;
                font-family: 'icomoon', sans-serif;
                &:before {
                  content: '\e916';
                }
                &:after {
                  content: '\e917';
                }
                .p-sortable-column-icon {
                  display: none;
                }
              }

              &.p-highlight {
                background-color: var(--white);
                color: var(--light-color);
                box-shadow: none;
              }
              &:not(.p-highlight):hover {
                background-color: var(--white);
                color: var(--light-color);
              }
              &[aria-sort='ascending'] {
                .p-icon-wrapper {
                  &:before {
                    color: var(--primary);
                  }
                }
              }
              &[aria-sort='descending'] {
                .p-icon-wrapper {
                  &:after {
                    color: var(--primary);
                  }
                }
              }
            }
          }
        }
      }

      .p-datatable-tbody {
        > tr {
          &:focus {
            outline: 0;
          }

          &:hover {
            background-color: var(--hover-color);
          }

          td {
            border: 1px solid var(--light-color-2);
            border-width: 0 0 1px 0;
            padding: 8px 10px;
            height: 51px;
            color: var(--base-color);
            cursor: pointer;
            &:nth-child(-n + 2) {
              > div {
                display: flex;
                align-items: center;
                .icon-error {
                  margin-left: 6px;
                }
              }
            }
            &.empty-message {
              cursor: auto;
            }
          }
        }

        > tr {
          &.cancelled-row {
            position: relative;
            &::after{
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: #cdcdcd6c;
              cursor: not-allowed;
              z-index: 2;
            }

            > td.p-frozen-column {
              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #cdcdcd6c;
                cursor: not-allowed;
                z-index: 2;
                pointer-events: none;
              }
            }
            
            td:first-child {
              &.p-frozen-column {
                z-index: 4;
              }
            }
            p-tableCheckbox{
              position: relative;
              z-index: 3;
            }
          }
        }
      }
    }
  }

  .p-component-overlay {
    background-color: rgb(255 255 255 / 90%);
  }

  &.no-cursor {
    .p-datatable table .p-datatable-tbody > tr td {
      cursor: default;
    }
  }
  .p-datatable-loading-overlay {
    min-height: 500px;
  }
  &.padd-0 {
    .p-datatable {
      padding: 0 0 var(--lg-space);
    }
  }

  .cell-selected {
    background-color: #E5F3FA;
    color: #0f5132;
  }
  .p-datatable-cell.selected {
    background-color: #cce5ff;
  }

  .p-datatable-loading-overlay {
    svg {
      width: 50px;
      height: 50px;
    }
  }
  

  ::-webkit-scrollbar {
    width: 8px !important;
    height: 8px;
    display: block !important;
  }
  ::-webkit-scrollbar-track {
    background: var(--scroll-track-color);
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--scroll-color);
    border-radius: 8px;
  }

  * {
    scrollbar-width: auto !important;
  }

}
