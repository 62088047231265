.d-flex {
  display: flex !important;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.f-bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.padding-10 {
  padding: 10px;
}

.max-height-200 {
  max-height: 200px;
}

.gray-border-1px {
  border: 1px solid #ddd;
}
.flex-wrap {
  flex-wrap: wrap;
}

.gap-32 {
  gap: 32px;
}

.transparent {
  background-color: transparent;
}

.text-nowrap {
  text-wrap: nowrap;
}

.color-primary {
  color: var(--primary);
}

.color-primary-2 {
  color: var(--primary-2);
}

.color-gray {
  color: (var(--gray-color));
}

.color-dark-gray {
  color: (var(--dark-gray-color));
}

.color-white {
  color: var(--white);
}

.text-center {
  text-align: center;
}

.border-blue-color-1 {
  border-color: (var(--blue-color-1)) !important;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-top {
  border-top: 1px solid var(--light-color-2);
}

.border-bottom {
  border-bottom: 1px solid var(--light-color-2);
}

.background-light-dark {
  background-color: var(--light-dark-color);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.line-through {
  text-decoration: line-through;
}

.pointer-events-none {
  pointer-events: none;
  user-select: none;
}

.right-border {
  border-right: 1px solid var(--border-color);
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.right-0 {
  right: 0;
}

.text-align-right {
  text-align: right;
}
