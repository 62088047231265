.confirm-dialog {
  .p-dialog-footer {
    margin-top: 20px;

    .p-button {
      border: none;
      box-shadow: none;
      outline: none;

      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      }

      .p-icon-wrapper {
        display: none;
      }

      .p-button-label {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
}
