input:-internal-autofill-selected,
input:-webkit-autofill,
textarea:-internal-autofill-selected,
textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px var(--form-bg) inset !important;

  &:hover,
  &:focus,
  &:active {
    -webkit-box-shadow: 0 0 0 30px var(--form-bg) inset !important;
  }
}

p-inputnumber {
  width: 100%;

  .p-inputnumber {
    width: 100%;

    .p-inputnumber-button-group {
      button {
        background-color: #F5F5F5;
        border: 1px solid var(--border-color);
        color: var(--dark-gray-color);

        &:hover {
          background-color: white;
        }
      }
    }
  }
}

.p-inputtext {
  &:not(.p-dropdown-label) {
    border: 1px solid var(--border-color);
    border-radius: var(--sm-border-radius);
    padding: 7px 15px;
    font-size: var(--base-fontsize);
    width: 100%;
    color: var(--base-color);
    background-color: var(--white);
    font-family: var(--font-family);
    @include placeholder-color(var(--placeholder-color));
    &:disabled {
      @include placeholder-color(transparent !important);
    }

    &:enabled {
      &:hover {
        border-color: var(--border-color);
      }

      &:focus {
        box-shadow: none;
        border-color: var(--border-focus-color);
      }
    }

    &.p-disabled,
    &.p-component:disabled {
      @include placeholder-color(var(--disabled-color));
      opacity: 1;
      border-color: var(--form-bg);
      color: var(--disabled-color);
    }
  }
  &.p-inputtextarea {
    min-height: 33px;
  }

  &.ng-dirty.ng-invalid {
    border-color: var(--danger-color)
  }
}

.p-input-icon-left {
  display: flex;
  align-items: center;

  em {
    left: 15px;
    position: absolute;
    color: #787f90;
    font-size: var(--fontsize);
  }

  .p-inputtext {
    padding-left: 40px;
  }
}

.p-input-icon-right {
  display: flex;
  align-items: center;

  em {
    right: 15px;
    position: absolute;
    color: #787f90;
    font-size: var(--fontsize);
  }

  .p-inputtext {
    padding-right: 40px;
  }
}
.field {
  margin-bottom: var(--md-space);
}
form {
  .field.grid > label {
    display: inline-block;
    margin-top: 7px;
  }

  &.search-form {
    p-dropdown .p-dropdown,
    input {
      background-color: var(--white);
      &:not(.p-dropdown-label) {
        background-color: var(--white);
      }
    }
  }
}

.field.grid > label {
  display: inline-block;
  margin-top: 7px;

  em {
    font-size: var(--md-fontsize);
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
  }
}

.validation-control-error {
  color: var(--danger-color);
  font-size: var(--sm-fontsize);
  margin-top: 5px;
  display: block;
}

.p-inputwrapper.ng-invalid.ng-touched,
.p-inputwrapper.ng-invalid.ng-dirty,
.has-error-inputtext:has(.ng-invalid.ng-dirty),
.has-error-inputtext:has(.ng-invalid.ng-touched) {
  position: relative;
  display: flex;
  .p-inputtext {
    border-color: var(--danger-color) !important;
    padding-right: 34px;
  }
}

.viewMode {
  .p-inputwrapper.ng-invalid.ng-touched,
  .p-inputwrapper.ng-invalid.ng-dirty,
  .has-error-inputtext:has(.ng-invalid.ng-dirty),
  .has-error-inputtext:has(.ng-invalid.ng-touched) {
    .p-inputtext {
      border-color: transparent !important;
    }
  }

  .p-inputtext {
    border-color: transparent !important;
    background-color: transparent;
    pointer-events: none;
    padding: 0;
    @include placeholder-color(transparent);
  }

  .icon-calendar {
    display: none;
  }

  input:-internal-autofill-selected,
  textarea:-internal-autofill-selected,
  input:-webkit-autofill,
  textarea:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;

    &:hover,
    &:focus,
    &:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }

  .validation-control-error {
    display: none;
  }

  .p-disabled,
  .p-component:disabled,
  .p-inputtext:not(.p-dropdown-label).p-disabled,
  .p-inputtext:not(.p-dropdown-label).p-component:disabled {
    color: var(--base-color);
    @include placeholder-color(var(--base-color));
  }
}

@media only screen and (max-width: 767px) {
  .viewMode {
    .p-inputtext {
      padding-left: 0;
    }
  }
}
