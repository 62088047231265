@import '@ng-select/ng-select/themes/material.theme.css';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
/* You can add global styles to this file, and also import other style files */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

q:before, q:after {
  content: "";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #393939;
  -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
}

.snackbar--error .mdc-snackbar__surface {
  background-color: var(--theme-warn) !important;
}
.snackbar--success .mdc-snackbar__surface {
  background-color: var(--theme-green) !important;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: var(--theme-white) !important;
}

.mat-mdc-snack-bar-label {
  word-break: break-all;
}

.mat-mdc-form-field-infix {
  min-height: 51px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  border-radius: 6px;
}

.custom_dialog .mat-mdc-form-field,
.form__block .mat-mdc-form-field {
  width: 100%;
}
.custom_dialog .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label,
.form__block .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 25px;
}
.custom_dialog .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.form__block .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-31.75px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.custom_dialog .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
.form__block .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  min-height: 51px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: flex;
  align-items: center;
}

.login-form .mat-mdc-form-field-infix {
  width: 100%;
}

lha-autocomplete-select {
  width: 100%;
}

.vessel__form .mdc-text-field--disabled .mdc-text-field__input {
  color: var(--theme-primary);
}
.vessel__form .mdc-text-field--disabled .mdc-notched-outline__leading,
.vessel__form .mdc-text-field--disabled .mdc-notched-outline__notch,
.vessel__form .mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.vessel__form .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:disabled .mdc-switch__icons {
  opacity: 1;
}
.vessel__form .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:disabled .mdc-switch__icon {
  fill: var(--theme-white);
}
.vessel__form .mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
  background: var(--theme-primary, var(--mdc-theme-surface, #fff));
}
.vessel__form .mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected .mdc-switch__handle::before {
  background: var(--mdc-switch-disabled-unselected-icon-color, var(--mdc-theme-surface, #fff));
}
.vessel__form .mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:disabled .mdc-switch__icon {
  fill: var(--mdc-switch-disabled-unselected-icon-color, var(--mdc-theme-on-primary, #fff));
}

.billing-period .mat-mdc-form-field-infix {
  width: auto;
}

@media (max-width: 900px) {
  lha-date-range .mat-mdc-form-field-infix {
    width: 150px;
  }
}
.mat-mdc-card {
  box-shadow: none !important;
}

.mdc-tab-indicator--active {
  font-weight: 500;
}
.mdc-tab-indicator--active .mdc-tab-indicator__content--underline {
  border-color: var(--theme-warn) !important;
}

.search .mat-mdc-form-field-infix,
.search-list .mat-mdc-form-field-infix,
.table__header .mat-mdc-form-field-infix,
.billing-period .mat-mdc-form-field-infix,
.voyage__filter .mat-mdc-form-field-infix,
.asset-mobile-well tr .mat-mdc-form-field-infix {
  min-height: 37px !important;
}
.search mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base,
.search-list mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base,
.table__header mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base,
.billing-period mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base,
.voyage__filter mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base,
.asset-mobile-well tr mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
  width: 37px;
  height: 37px;
  padding: 7px;
  margin: 0;
}
.search .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
.search-list .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
.table__header .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
.billing-period .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
.voyage__filter .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
.asset-mobile-well tr .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 10px;
}
.search .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label,
.search-list .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label,
.table__header .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label,
.billing-period .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label,
.voyage__filter .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label,
.asset-mobile-well tr .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 19px;
}
.search .mdc-text-field--filled .mdc-floating-label--float-above,
.search-list .mdc-text-field--filled .mdc-floating-label--float-above,
.table__header .mdc-text-field--filled .mdc-floating-label--float-above,
.billing-period .mdc-text-field--filled .mdc-floating-label--float-above,
.voyage__filter .mdc-text-field--filled .mdc-floating-label--float-above,
.asset-mobile-well tr .mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-113%) scale(0.65);
}
.search .mat-mdc-form-field-icon-prefix > .mat-icon,
.search .mat-mdc-form-field-icon-suffix > .mat-icon,
.search-list .mat-mdc-form-field-icon-prefix > .mat-icon,
.search-list .mat-mdc-form-field-icon-suffix > .mat-icon,
.table__header .mat-mdc-form-field-icon-prefix > .mat-icon,
.table__header .mat-mdc-form-field-icon-suffix > .mat-icon,
.billing-period .mat-mdc-form-field-icon-prefix > .mat-icon,
.billing-period .mat-mdc-form-field-icon-suffix > .mat-icon,
.voyage__filter .mat-mdc-form-field-icon-prefix > .mat-icon,
.voyage__filter .mat-mdc-form-field-icon-suffix > .mat-icon,
.asset-mobile-well tr .mat-mdc-form-field-icon-prefix > .mat-icon,
.asset-mobile-well tr .mat-mdc-form-field-icon-suffix > .mat-icon {
  padding: 7px;
}
.search .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix,
.search-list .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix,
.table__header .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix,
.billing-period .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix,
.voyage__filter .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix,
.asset-mobile-well tr .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 8px;
}
.search .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
.search-list .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
.table__header .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
.billing-period .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
.voyage__filter .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
.asset-mobile-well tr .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 9px;
  padding-bottom: 9px;
}
.search .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.search-list .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.table__header .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.billing-period .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.voyage__filter .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.asset-mobile-well tr .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-26.75px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.ng-select {
  padding-bottom: 0;
  margin-bottom: 20px;
}

.owner-select .ng-select {
  min-height: 37px;
}

.owner-select .ng-select .ng-select-container.ng-appearance-outline {
  min-height: 37px;
}

.ng-select .ng-select-container {
  overflow: visible !important;
}

.ng-select .ng-select-container .ng-value-container {
  border-top: 0.485em solid transparent;
}

.ng-select .ng-select-container.ng-appearance-outline {
  min-height: 51px;
}

.ng-select .ng-select-container.ng-appearance-outline:after {
  border-color: rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  top: 0;
  height: auto;
}

.ng-select.ng-select-multiple .ng-select-container.ng-appearance-outline .ng-arrow-wrapper {
  border-top: none;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  top: 20px;
}

.owner-select .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  top: 25%;
}

.ng-select .ng-has-value.ng-select-container .ng-value-container .ng-placeholder,
.ng-select.ng-select-opened .ng-select-container .ng-value-container .ng-placeholder {
  top: 16px;
}

.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  top: 16px;
  color: var(--theme-primary);
  left: 13px;
}

.ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: var(--theme-primary);
}

.ng-select .ng-select-container.ng-appearance-outline:hover:after {
  border-width: 1px;
}

.ng-select.ng-select-disabled .ng-select-container.ng-appearance-outline:hover:after {
  border-color: rgba(0, 0, 0, 0.38);
  opacity: 0.3;
}

.ng-select.ng-select-disabled .ng-select-container.ng-appearance-outline:after {
  background-size: 0;
  opacity: 0.3;
}

.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,
.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after {
  border-color: var(--theme-primary);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background: rgba(121, 148, 149, 0.2);
  color: var(--theme-primary);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  color: var(--theme-primary);
}

lha-multiselect ~ .mat-mdc-form-field-error,
lha-single-select ~ .mat-mdc-form-field-error {
  position: absolute;
  bottom: 0;
  left: 10px;
}
lha-multiselect.ng-invalid:not(.ng-untouched) .ng-select .ng-select-container:after,
lha-single-select.ng-invalid:not(.ng-untouched) .ng-select .ng-select-container:after {
  border-color: var(--theme-warn);
}
lha-multiselect.ng-invalid:not(.ng-untouched) .ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder,
lha-single-select.ng-invalid:not(.ng-untouched) .ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--theme-warn);
}
lha-multiselect.ng-invalid:not(.ng-untouched) .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow,
lha-single-select.ng-invalid:not(.ng-untouched) .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow {
  color: var(--theme-warn);
}
lha-multiselect.ng-invalid:not(.ng-untouched) .ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,
lha-multiselect.ng-invalid:not(.ng-untouched) .ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after,
lha-single-select.ng-invalid:not(.ng-untouched) .ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,
lha-single-select.ng-invalid:not(.ng-untouched) .ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after {
  border-color: var(--theme-warn);
}
lha-multiselect.ng-invalid:not(.ng-untouched) .ng-select .ng-select-container .ng-value-container .ng-placeholder,
lha-single-select.ng-invalid:not(.ng-untouched) .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: var(--theme-warn);
}

.form__box {
  position: relative;
}

.flex-1 {
  flex: 1;
  overflow: hidden;
}

lha-single-select.ng-invalid.ng-touched .ng-select {
  margin-bottom: -1px;
}

lha-single-select {
  position: relative;
  width: 100%;
  display: block;
}
lha-single-select .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  left: 13px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}
lha-single-select .ng-select .ng-select-container .ng-value-container {
  border-top: 0.84375em solid transparent;
}
lha-single-select .ng-select .ng-select-container.ng-appearance-outline {
  padding: 0 12px;
}
lha-single-select .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input input {
  font-size: 16px;
}
lha-single-select ~ div {
  padding-left: 12px;
}
lha-single-select + .ng-star-inserted {
  padding: 0 16px !important;
}
lha-single-select .ng-select .ng-select-container .ng-value-container .ng-input {
  top: 52%;
  padding: 0 15px;
  transform: translateY(-50%);
}

lha-single-select + div mat-error {
  animation: validationError 0.28s ease-out;
}

@keyframes validationError {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
.ng-select .ng-spinner-loader {
  top: 10px;
}

.ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:after,
.ng-select.ng-select-multiple.ng-select-disabled .ng-appearance-outline:hover:after {
  border: 1px solid rgba(0, 0, 0, 0.38);
}

.asset-mobile-well .ng-select .ng-select-container.ng-appearance-outline {
  height: 37px;
  min-height: auto;
}
.asset-mobile-well .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  top: 12px;
}
.asset-mobile-well .ng-select .ng-has-value .ng-select-container .ng-value-container .ng-placeholder,
.asset-mobile-well .ng-select.ng-select-opened .ng-select-container .ng-value-container .ng-placeholder,
.asset-mobile-well .ng-select .ng-has-value.ng-select-container .ng-value-container .ng-placeholder {
  top: 16px;
}
.asset-mobile-well .ng-select.ng-select-single .ng-select-container.ng-appearance-outline .ng-arrow-wrapper {
  bottom: 13px;
}
.asset-mobile-well .ng-select.ng-select-single .ng-select-container.ng-appearance-outline .ng-clear-wrapper {
  bottom: 10px;
}
.asset-mobile-well lha-single-select .ng-select .ng-select-container .ng-value-container {
  border-top: 0.3em solid transparent;
}

ngx-mat-datetime-content {
  margin: 16px 0 0 -16px;
}
ngx-mat-datetime-content .mat-calendar-body-cell {
  height: auto !important;
  position: relative !important;
  left: auto !important;
  top: auto !important;
}

button.mat-calendar-body-cell {
  height: 100% !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
}

.mat-mdc-form-field-icon-suffix {
  min-width: 40px;
}

mat-datepicker-toggle {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  cursor: pointer;
}

lha-date-range .mat-mdc-form-field-icon-suffix,
.form__box--date .mat-mdc-form-field-icon-suffix {
  min-width: 80px;
  display: flex;
  justify-content: flex-end;
}
lha-date-range mat-datepicker-toggle,
.form__box--date mat-datepicker-toggle {
  width: calc(100% - 33px);
  margin-right: 33px;
}
lha-date-range mat-datepicker-toggle.toggle-clear,
.form__box--date mat-datepicker-toggle.toggle-clear {
  width: 37px;
  margin: 0;
  position: static;
}

.mat-mdc-dialog-title {
  padding: 16px 16px 16px 24px !important;
  font-size: 18px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-bottom: 1px solid #dddddd;
}
.mat-mdc-dialog-title::before {
  display: none !important;
}
.mat-mdc-dialog-title .mat-icon {
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  cursor: pointer;
}

mat-dialog-content {
  padding: 24px !important;
}

mat-dialog-actions {
  padding: 16px 24px !important;
  border-top: 1px solid #dddddd !important;
}
mat-dialog-actions button {
  margin-right: 0 !important;
}
mat-dialog-actions button ~ button {
  margin-left: 8px !important;
}

:root {
  --base-color: #08162a;
  --hover-secondary: #08164f;
  --primary: #d6002a;
  --hover-primary: #ad030f;
  --primary-2: #00855a;
  --primary-2-op1: rgba(0, 133, 90, 0.1);
  --primary-3: #004f95;
  --primary-3-op1: rgba(0, 79, 149, 0.1);
  --primary-4: #ffd400;
  --primary-5: #006ab9;
  --primary-6: #ffa754;
  --danger-color: #e30000;
  --white: #fff;
  --light-color: #787f90;
  --light-color-2: #eceef1;
  --border-color: #dddddd;
  --light-border-color: #e5e5e5;
  --light-dark-color: #EEEEEE;
  --border-focus-color: #787f90;
  --disabled-color: #b6bbc7;
  --placeholder-color: #787f90;
  --label-color: #737373;
  --gray-color: #999999;
  --dark-gray-color: #666666;
  --form-bg: #f4f5f7;
  --hover-color: #f4f5f7;
  --scroll-color: #c5c5c5;
  --scroll-track-color: #f1f1f1;
  --tab-focus-color: #fafafc;
  --blue-color-1: #008BCC;
  --font-family: "Noto Sans", sans-serif;
  --box-shadow: 0px 1px 2px rgba(8, 22, 42, 0.15);
  --border-radius: 2px;
  --sm-border-radius: 6px;
  --md-border-radius: 8px;
  --lg-border-radius: 16px;
  --base-fontsize: 14px;
  --sm-fontsize: 12px;
  --md-fontsize: 16px;
  --fontsize: 18px;
  --lg-fontsize: 20px;
  --xl-fontsize: 24px;
  --lg-space: 24px;
  --md-space: 16px;
}

p-multiselect .new-version-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-label, p-multiselect .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mt-1 {
  margin-top: 1px;
}

.mr-1 {
  margin-right: 1px;
}

.mb-1 {
  margin-bottom: 1px;
}

.ml-1 {
  margin-left: 1px;
}

.mt-2 {
  margin-top: 2px;
}

.mr-2 {
  margin-right: 2px;
}

.mb-2 {
  margin-bottom: 2px;
}

.ml-2 {
  margin-left: 2px;
}

.mt-3 {
  margin-top: 3px;
}

.mr-3 {
  margin-right: 3px;
}

.mb-3 {
  margin-bottom: 3px;
}

.ml-3 {
  margin-left: 3px;
}

.mt-4 {
  margin-top: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.mb-4 {
  margin-bottom: 4px;
}

.ml-4 {
  margin-left: 4px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mt-6 {
  margin-top: 6px;
}

.mr-6 {
  margin-right: 6px;
}

.mb-6 {
  margin-bottom: 6px;
}

.ml-6 {
  margin-left: 6px;
}

.mt-7 {
  margin-top: 7px;
}

.mr-7 {
  margin-right: 7px;
}

.mb-7 {
  margin-bottom: 7px;
}

.ml-7 {
  margin-left: 7px;
}

.mt-8 {
  margin-top: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-9 {
  margin-top: 9px;
}

.mr-9 {
  margin-right: 9px;
}

.mb-9 {
  margin-bottom: 9px;
}

.ml-9 {
  margin-left: 9px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-11 {
  margin-top: 11px;
}

.mr-11 {
  margin-right: 11px;
}

.mb-11 {
  margin-bottom: 11px;
}

.ml-11 {
  margin-left: 11px;
}

.mt-12 {
  margin-top: 12px;
}

.mr-12 {
  margin-right: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.ml-12 {
  margin-left: 12px;
}

.mt-13 {
  margin-top: 13px;
}

.mr-13 {
  margin-right: 13px;
}

.mb-13 {
  margin-bottom: 13px;
}

.ml-13 {
  margin-left: 13px;
}

.mt-14 {
  margin-top: 14px;
}

.mr-14 {
  margin-right: 14px;
}

.mb-14 {
  margin-bottom: 14px;
}

.ml-14 {
  margin-left: 14px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mt-16 {
  margin-top: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.mt-17 {
  margin-top: 17px;
}

.mr-17 {
  margin-right: 17px;
}

.mb-17 {
  margin-bottom: 17px;
}

.ml-17 {
  margin-left: 17px;
}

.mt-18 {
  margin-top: 18px;
}

.mr-18 {
  margin-right: 18px;
}

.mb-18 {
  margin-bottom: 18px;
}

.ml-18 {
  margin-left: 18px;
}

.mt-19 {
  margin-top: 19px;
}

.mr-19 {
  margin-right: 19px;
}

.mb-19 {
  margin-bottom: 19px;
}

.ml-19 {
  margin-left: 19px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-21 {
  margin-top: 21px;
}

.mr-21 {
  margin-right: 21px;
}

.mb-21 {
  margin-bottom: 21px;
}

.ml-21 {
  margin-left: 21px;
}

.mt-22 {
  margin-top: 22px;
}

.mr-22 {
  margin-right: 22px;
}

.mb-22 {
  margin-bottom: 22px;
}

.ml-22 {
  margin-left: 22px;
}

.mt-23 {
  margin-top: 23px;
}

.mr-23 {
  margin-right: 23px;
}

.mb-23 {
  margin-bottom: 23px;
}

.ml-23 {
  margin-left: 23px;
}

.mt-24 {
  margin-top: 24px;
}

.mr-24 {
  margin-right: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.ml-24 {
  margin-left: 24px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mt-26 {
  margin-top: 26px;
}

.mr-26 {
  margin-right: 26px;
}

.mb-26 {
  margin-bottom: 26px;
}

.ml-26 {
  margin-left: 26px;
}

.mt-27 {
  margin-top: 27px;
}

.mr-27 {
  margin-right: 27px;
}

.mb-27 {
  margin-bottom: 27px;
}

.ml-27 {
  margin-left: 27px;
}

.mt-28 {
  margin-top: 28px;
}

.mr-28 {
  margin-right: 28px;
}

.mb-28 {
  margin-bottom: 28px;
}

.ml-28 {
  margin-left: 28px;
}

.mt-29 {
  margin-top: 29px;
}

.mr-29 {
  margin-right: 29px;
}

.mb-29 {
  margin-bottom: 29px;
}

.ml-29 {
  margin-left: 29px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.mt-31 {
  margin-top: 31px;
}

.mr-31 {
  margin-right: 31px;
}

.mb-31 {
  margin-bottom: 31px;
}

.ml-31 {
  margin-left: 31px;
}

.mt-32 {
  margin-top: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.mt-33 {
  margin-top: 33px;
}

.mr-33 {
  margin-right: 33px;
}

.mb-33 {
  margin-bottom: 33px;
}

.ml-33 {
  margin-left: 33px;
}

.mt-34 {
  margin-top: 34px;
}

.mr-34 {
  margin-right: 34px;
}

.mb-34 {
  margin-bottom: 34px;
}

.ml-34 {
  margin-left: 34px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.mt-36 {
  margin-top: 36px;
}

.mr-36 {
  margin-right: 36px;
}

.mb-36 {
  margin-bottom: 36px;
}

.ml-36 {
  margin-left: 36px;
}

.mt-37 {
  margin-top: 37px;
}

.mr-37 {
  margin-right: 37px;
}

.mb-37 {
  margin-bottom: 37px;
}

.ml-37 {
  margin-left: 37px;
}

.mt-38 {
  margin-top: 38px;
}

.mr-38 {
  margin-right: 38px;
}

.mb-38 {
  margin-bottom: 38px;
}

.ml-38 {
  margin-left: 38px;
}

.mt-39 {
  margin-top: 39px;
}

.mr-39 {
  margin-right: 39px;
}

.mb-39 {
  margin-bottom: 39px;
}

.ml-39 {
  margin-left: 39px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.pt-1 {
  padding-top: 1px;
}

.pr-1 {
  padding-right: 1px;
}

.pb-1 {
  padding-bottom: 1px;
}

.pl-1 {
  padding-left: 1px;
}

.p-1 {
  padding: 1px;
}

.pt-2 {
  padding-top: 2px;
}

.pr-2 {
  padding-right: 2px;
}

.pb-2 {
  padding-bottom: 2px;
}

.pl-2 {
  padding-left: 2px;
}

.p-2 {
  padding: 2px;
}

.pt-3 {
  padding-top: 3px;
}

.pr-3 {
  padding-right: 3px;
}

.pb-3 {
  padding-bottom: 3px;
}

.pl-3 {
  padding-left: 3px;
}

.p-3 {
  padding: 3px;
}

.pt-4 {
  padding-top: 4px;
}

.pr-4 {
  padding-right: 4px;
}

.pb-4 {
  padding-bottom: 4px;
}

.pl-4 {
  padding-left: 4px;
}

.p-4 {
  padding: 4px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.p-5 {
  padding: 5px;
}

.pt-6 {
  padding-top: 6px;
}

.pr-6 {
  padding-right: 6px;
}

.pb-6 {
  padding-bottom: 6px;
}

.pl-6 {
  padding-left: 6px;
}

.p-6 {
  padding: 6px;
}

.pt-7 {
  padding-top: 7px;
}

.pr-7 {
  padding-right: 7px;
}

.pb-7 {
  padding-bottom: 7px;
}

.pl-7 {
  padding-left: 7px;
}

.p-7 {
  padding: 7px;
}

.pt-8 {
  padding-top: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pl-8 {
  padding-left: 8px;
}

.p-8 {
  padding: 8px;
}

.pt-9 {
  padding-top: 9px;
}

.pr-9 {
  padding-right: 9px;
}

.pb-9 {
  padding-bottom: 9px;
}

.pl-9 {
  padding-left: 9px;
}

.p-9 {
  padding: 9px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.p-10 {
  padding: 10px;
}

.pt-11 {
  padding-top: 11px;
}

.pr-11 {
  padding-right: 11px;
}

.pb-11 {
  padding-bottom: 11px;
}

.pl-11 {
  padding-left: 11px;
}

.p-11 {
  padding: 11px;
}

.pt-12 {
  padding-top: 12px;
}

.pr-12 {
  padding-right: 12px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pl-12 {
  padding-left: 12px;
}

.p-12 {
  padding: 12px;
}

.pt-13 {
  padding-top: 13px;
}

.pr-13 {
  padding-right: 13px;
}

.pb-13 {
  padding-bottom: 13px;
}

.pl-13 {
  padding-left: 13px;
}

.p-13 {
  padding: 13px;
}

.pt-14 {
  padding-top: 14px;
}

.pr-14 {
  padding-right: 14px;
}

.pb-14 {
  padding-bottom: 14px;
}

.pl-14 {
  padding-left: 14px;
}

.p-14 {
  padding: 14px;
}

.pt-15 {
  padding-top: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.p-15 {
  padding: 15px;
}

.pt-16 {
  padding-top: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.p-16 {
  padding: 16px;
}

.pt-17 {
  padding-top: 17px;
}

.pr-17 {
  padding-right: 17px;
}

.pb-17 {
  padding-bottom: 17px;
}

.pl-17 {
  padding-left: 17px;
}

.p-17 {
  padding: 17px;
}

.pt-18 {
  padding-top: 18px;
}

.pr-18 {
  padding-right: 18px;
}

.pb-18 {
  padding-bottom: 18px;
}

.pl-18 {
  padding-left: 18px;
}

.p-18 {
  padding: 18px;
}

.pt-19 {
  padding-top: 19px;
}

.pr-19 {
  padding-right: 19px;
}

.pb-19 {
  padding-bottom: 19px;
}

.pl-19 {
  padding-left: 19px;
}

.p-19 {
  padding: 19px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.p-20 {
  padding: 20px;
}

.pt-21 {
  padding-top: 21px;
}

.pr-21 {
  padding-right: 21px;
}

.pb-21 {
  padding-bottom: 21px;
}

.pl-21 {
  padding-left: 21px;
}

.p-21 {
  padding: 21px;
}

.pt-22 {
  padding-top: 22px;
}

.pr-22 {
  padding-right: 22px;
}

.pb-22 {
  padding-bottom: 22px;
}

.pl-22 {
  padding-left: 22px;
}

.p-22 {
  padding: 22px;
}

.pt-23 {
  padding-top: 23px;
}

.pr-23 {
  padding-right: 23px;
}

.pb-23 {
  padding-bottom: 23px;
}

.pl-23 {
  padding-left: 23px;
}

.p-23 {
  padding: 23px;
}

.pt-24 {
  padding-top: 24px;
}

.pr-24 {
  padding-right: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.p-24 {
  padding: 24px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.p-25 {
  padding: 25px;
}

.pt-26 {
  padding-top: 26px;
}

.pr-26 {
  padding-right: 26px;
}

.pb-26 {
  padding-bottom: 26px;
}

.pl-26 {
  padding-left: 26px;
}

.p-26 {
  padding: 26px;
}

.pt-27 {
  padding-top: 27px;
}

.pr-27 {
  padding-right: 27px;
}

.pb-27 {
  padding-bottom: 27px;
}

.pl-27 {
  padding-left: 27px;
}

.p-27 {
  padding: 27px;
}

.pt-28 {
  padding-top: 28px;
}

.pr-28 {
  padding-right: 28px;
}

.pb-28 {
  padding-bottom: 28px;
}

.pl-28 {
  padding-left: 28px;
}

.p-28 {
  padding: 28px;
}

.pt-29 {
  padding-top: 29px;
}

.pr-29 {
  padding-right: 29px;
}

.pb-29 {
  padding-bottom: 29px;
}

.pl-29 {
  padding-left: 29px;
}

.p-29 {
  padding: 29px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.p-30 {
  padding: 30px;
}

.pt-31 {
  padding-top: 31px;
}

.pr-31 {
  padding-right: 31px;
}

.pb-31 {
  padding-bottom: 31px;
}

.pl-31 {
  padding-left: 31px;
}

.p-31 {
  padding: 31px;
}

.pt-32 {
  padding-top: 32px;
}

.pr-32 {
  padding-right: 32px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pl-32 {
  padding-left: 32px;
}

.p-32 {
  padding: 32px;
}

.pt-33 {
  padding-top: 33px;
}

.pr-33 {
  padding-right: 33px;
}

.pb-33 {
  padding-bottom: 33px;
}

.pl-33 {
  padding-left: 33px;
}

.p-33 {
  padding: 33px;
}

.pt-34 {
  padding-top: 34px;
}

.pr-34 {
  padding-right: 34px;
}

.pb-34 {
  padding-bottom: 34px;
}

.pl-34 {
  padding-left: 34px;
}

.p-34 {
  padding: 34px;
}

.pt-35 {
  padding-top: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.p-35 {
  padding: 35px;
}

.pt-36 {
  padding-top: 36px;
}

.pr-36 {
  padding-right: 36px;
}

.pb-36 {
  padding-bottom: 36px;
}

.pl-36 {
  padding-left: 36px;
}

.p-36 {
  padding: 36px;
}

.pt-37 {
  padding-top: 37px;
}

.pr-37 {
  padding-right: 37px;
}

.pb-37 {
  padding-bottom: 37px;
}

.pl-37 {
  padding-left: 37px;
}

.p-37 {
  padding: 37px;
}

.pt-38 {
  padding-top: 38px;
}

.pr-38 {
  padding-right: 38px;
}

.pb-38 {
  padding-bottom: 38px;
}

.pl-38 {
  padding-left: 38px;
}

.p-38 {
  padding: 38px;
}

.pt-39 {
  padding-top: 39px;
}

.pr-39 {
  padding-right: 39px;
}

.pb-39 {
  padding-bottom: 39px;
}

.pl-39 {
  padding-left: 39px;
}

.p-39 {
  padding: 39px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.p-40 {
  padding: 40px;
}

.pt-41 {
  padding-top: 41px;
}

.pr-41 {
  padding-right: 41px;
}

.pb-41 {
  padding-bottom: 41px;
}

.pl-41 {
  padding-left: 41px;
}

.p-41 {
  padding: 41px;
}

.pt-42 {
  padding-top: 42px;
}

.pr-42 {
  padding-right: 42px;
}

.pb-42 {
  padding-bottom: 42px;
}

.pl-42 {
  padding-left: 42px;
}

.p-42 {
  padding: 42px;
}

.pt-43 {
  padding-top: 43px;
}

.pr-43 {
  padding-right: 43px;
}

.pb-43 {
  padding-bottom: 43px;
}

.pl-43 {
  padding-left: 43px;
}

.p-43 {
  padding: 43px;
}

.pt-44 {
  padding-top: 44px;
}

.pr-44 {
  padding-right: 44px;
}

.pb-44 {
  padding-bottom: 44px;
}

.pl-44 {
  padding-left: 44px;
}

.p-44 {
  padding: 44px;
}

.pt-45 {
  padding-top: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.p-45 {
  padding: 45px;
}

.pt-46 {
  padding-top: 46px;
}

.pr-46 {
  padding-right: 46px;
}

.pb-46 {
  padding-bottom: 46px;
}

.pl-46 {
  padding-left: 46px;
}

.p-46 {
  padding: 46px;
}

.pt-47 {
  padding-top: 47px;
}

.pr-47 {
  padding-right: 47px;
}

.pb-47 {
  padding-bottom: 47px;
}

.pl-47 {
  padding-left: 47px;
}

.p-47 {
  padding: 47px;
}

.pt-48 {
  padding-top: 48px;
}

.pr-48 {
  padding-right: 48px;
}

.pb-48 {
  padding-bottom: 48px;
}

.pl-48 {
  padding-left: 48px;
}

.p-48 {
  padding: 48px;
}

.pt-49 {
  padding-top: 49px;
}

.pr-49 {
  padding-right: 49px;
}

.pb-49 {
  padding-bottom: 49px;
}

.pl-49 {
  padding-left: 49px;
}

.p-49 {
  padding: 49px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.p-50 {
  padding: 50px;
}

.pt-51 {
  padding-top: 51px;
}

.pr-51 {
  padding-right: 51px;
}

.pb-51 {
  padding-bottom: 51px;
}

.pl-51 {
  padding-left: 51px;
}

.p-51 {
  padding: 51px;
}

.pt-52 {
  padding-top: 52px;
}

.pr-52 {
  padding-right: 52px;
}

.pb-52 {
  padding-bottom: 52px;
}

.pl-52 {
  padding-left: 52px;
}

.p-52 {
  padding: 52px;
}

.pt-53 {
  padding-top: 53px;
}

.pr-53 {
  padding-right: 53px;
}

.pb-53 {
  padding-bottom: 53px;
}

.pl-53 {
  padding-left: 53px;
}

.p-53 {
  padding: 53px;
}

.pt-54 {
  padding-top: 54px;
}

.pr-54 {
  padding-right: 54px;
}

.pb-54 {
  padding-bottom: 54px;
}

.pl-54 {
  padding-left: 54px;
}

.p-54 {
  padding: 54px;
}

.pt-55 {
  padding-top: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.p-55 {
  padding: 55px;
}

.pt-56 {
  padding-top: 56px;
}

.pr-56 {
  padding-right: 56px;
}

.pb-56 {
  padding-bottom: 56px;
}

.pl-56 {
  padding-left: 56px;
}

.p-56 {
  padding: 56px;
}

.pt-57 {
  padding-top: 57px;
}

.pr-57 {
  padding-right: 57px;
}

.pb-57 {
  padding-bottom: 57px;
}

.pl-57 {
  padding-left: 57px;
}

.p-57 {
  padding: 57px;
}

.pt-58 {
  padding-top: 58px;
}

.pr-58 {
  padding-right: 58px;
}

.pb-58 {
  padding-bottom: 58px;
}

.pl-58 {
  padding-left: 58px;
}

.p-58 {
  padding: 58px;
}

.pt-59 {
  padding-top: 59px;
}

.pr-59 {
  padding-right: 59px;
}

.pb-59 {
  padding-bottom: 59px;
}

.pl-59 {
  padding-left: 59px;
}

.p-59 {
  padding: 59px;
}

.pt-60 {
  padding-top: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.p-60 {
  padding: 60px;
}

.pt-61 {
  padding-top: 61px;
}

.pr-61 {
  padding-right: 61px;
}

.pb-61 {
  padding-bottom: 61px;
}

.pl-61 {
  padding-left: 61px;
}

.p-61 {
  padding: 61px;
}

.pt-62 {
  padding-top: 62px;
}

.pr-62 {
  padding-right: 62px;
}

.pb-62 {
  padding-bottom: 62px;
}

.pl-62 {
  padding-left: 62px;
}

.p-62 {
  padding: 62px;
}

.pt-63 {
  padding-top: 63px;
}

.pr-63 {
  padding-right: 63px;
}

.pb-63 {
  padding-bottom: 63px;
}

.pl-63 {
  padding-left: 63px;
}

.p-63 {
  padding: 63px;
}

.pt-64 {
  padding-top: 64px;
}

.pr-64 {
  padding-right: 64px;
}

.pb-64 {
  padding-bottom: 64px;
}

.pl-64 {
  padding-left: 64px;
}

.p-64 {
  padding: 64px;
}

.pt-65 {
  padding-top: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.p-65 {
  padding: 65px;
}

.pt-66 {
  padding-top: 66px;
}

.pr-66 {
  padding-right: 66px;
}

.pb-66 {
  padding-bottom: 66px;
}

.pl-66 {
  padding-left: 66px;
}

.p-66 {
  padding: 66px;
}

.pt-67 {
  padding-top: 67px;
}

.pr-67 {
  padding-right: 67px;
}

.pb-67 {
  padding-bottom: 67px;
}

.pl-67 {
  padding-left: 67px;
}

.p-67 {
  padding: 67px;
}

.pt-68 {
  padding-top: 68px;
}

.pr-68 {
  padding-right: 68px;
}

.pb-68 {
  padding-bottom: 68px;
}

.pl-68 {
  padding-left: 68px;
}

.p-68 {
  padding: 68px;
}

.pt-69 {
  padding-top: 69px;
}

.pr-69 {
  padding-right: 69px;
}

.pb-69 {
  padding-bottom: 69px;
}

.pl-69 {
  padding-left: 69px;
}

.p-69 {
  padding: 69px;
}

.pt-70 {
  padding-top: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.p-70 {
  padding: 70px;
}

.pt-71 {
  padding-top: 71px;
}

.pr-71 {
  padding-right: 71px;
}

.pb-71 {
  padding-bottom: 71px;
}

.pl-71 {
  padding-left: 71px;
}

.p-71 {
  padding: 71px;
}

.pt-72 {
  padding-top: 72px;
}

.pr-72 {
  padding-right: 72px;
}

.pb-72 {
  padding-bottom: 72px;
}

.pl-72 {
  padding-left: 72px;
}

.p-72 {
  padding: 72px;
}

.pt-73 {
  padding-top: 73px;
}

.pr-73 {
  padding-right: 73px;
}

.pb-73 {
  padding-bottom: 73px;
}

.pl-73 {
  padding-left: 73px;
}

.p-73 {
  padding: 73px;
}

.pt-74 {
  padding-top: 74px;
}

.pr-74 {
  padding-right: 74px;
}

.pb-74 {
  padding-bottom: 74px;
}

.pl-74 {
  padding-left: 74px;
}

.p-74 {
  padding: 74px;
}

.pt-75 {
  padding-top: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.p-75 {
  padding: 75px;
}

.pt-76 {
  padding-top: 76px;
}

.pr-76 {
  padding-right: 76px;
}

.pb-76 {
  padding-bottom: 76px;
}

.pl-76 {
  padding-left: 76px;
}

.p-76 {
  padding: 76px;
}

.pt-77 {
  padding-top: 77px;
}

.pr-77 {
  padding-right: 77px;
}

.pb-77 {
  padding-bottom: 77px;
}

.pl-77 {
  padding-left: 77px;
}

.p-77 {
  padding: 77px;
}

.pt-78 {
  padding-top: 78px;
}

.pr-78 {
  padding-right: 78px;
}

.pb-78 {
  padding-bottom: 78px;
}

.pl-78 {
  padding-left: 78px;
}

.p-78 {
  padding: 78px;
}

.pt-79 {
  padding-top: 79px;
}

.pr-79 {
  padding-right: 79px;
}

.pb-79 {
  padding-bottom: 79px;
}

.pl-79 {
  padding-left: 79px;
}

.p-79 {
  padding: 79px;
}

.pt-80 {
  padding-top: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.p-80 {
  padding: 80px;
}

.pt-81 {
  padding-top: 81px;
}

.pr-81 {
  padding-right: 81px;
}

.pb-81 {
  padding-bottom: 81px;
}

.pl-81 {
  padding-left: 81px;
}

.p-81 {
  padding: 81px;
}

.pt-82 {
  padding-top: 82px;
}

.pr-82 {
  padding-right: 82px;
}

.pb-82 {
  padding-bottom: 82px;
}

.pl-82 {
  padding-left: 82px;
}

.p-82 {
  padding: 82px;
}

.pt-83 {
  padding-top: 83px;
}

.pr-83 {
  padding-right: 83px;
}

.pb-83 {
  padding-bottom: 83px;
}

.pl-83 {
  padding-left: 83px;
}

.p-83 {
  padding: 83px;
}

.pt-84 {
  padding-top: 84px;
}

.pr-84 {
  padding-right: 84px;
}

.pb-84 {
  padding-bottom: 84px;
}

.pl-84 {
  padding-left: 84px;
}

.p-84 {
  padding: 84px;
}

.pt-85 {
  padding-top: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.p-85 {
  padding: 85px;
}

.pt-86 {
  padding-top: 86px;
}

.pr-86 {
  padding-right: 86px;
}

.pb-86 {
  padding-bottom: 86px;
}

.pl-86 {
  padding-left: 86px;
}

.p-86 {
  padding: 86px;
}

.pt-87 {
  padding-top: 87px;
}

.pr-87 {
  padding-right: 87px;
}

.pb-87 {
  padding-bottom: 87px;
}

.pl-87 {
  padding-left: 87px;
}

.p-87 {
  padding: 87px;
}

.pt-88 {
  padding-top: 88px;
}

.pr-88 {
  padding-right: 88px;
}

.pb-88 {
  padding-bottom: 88px;
}

.pl-88 {
  padding-left: 88px;
}

.p-88 {
  padding: 88px;
}

.pt-89 {
  padding-top: 89px;
}

.pr-89 {
  padding-right: 89px;
}

.pb-89 {
  padding-bottom: 89px;
}

.pl-89 {
  padding-left: 89px;
}

.p-89 {
  padding: 89px;
}

.pt-90 {
  padding-top: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.p-90 {
  padding: 90px;
}

.pt-91 {
  padding-top: 91px;
}

.pr-91 {
  padding-right: 91px;
}

.pb-91 {
  padding-bottom: 91px;
}

.pl-91 {
  padding-left: 91px;
}

.p-91 {
  padding: 91px;
}

.pt-92 {
  padding-top: 92px;
}

.pr-92 {
  padding-right: 92px;
}

.pb-92 {
  padding-bottom: 92px;
}

.pl-92 {
  padding-left: 92px;
}

.p-92 {
  padding: 92px;
}

.pt-93 {
  padding-top: 93px;
}

.pr-93 {
  padding-right: 93px;
}

.pb-93 {
  padding-bottom: 93px;
}

.pl-93 {
  padding-left: 93px;
}

.p-93 {
  padding: 93px;
}

.pt-94 {
  padding-top: 94px;
}

.pr-94 {
  padding-right: 94px;
}

.pb-94 {
  padding-bottom: 94px;
}

.pl-94 {
  padding-left: 94px;
}

.p-94 {
  padding: 94px;
}

.pt-95 {
  padding-top: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.p-95 {
  padding: 95px;
}

.pt-96 {
  padding-top: 96px;
}

.pr-96 {
  padding-right: 96px;
}

.pb-96 {
  padding-bottom: 96px;
}

.pl-96 {
  padding-left: 96px;
}

.p-96 {
  padding: 96px;
}

.pt-97 {
  padding-top: 97px;
}

.pr-97 {
  padding-right: 97px;
}

.pb-97 {
  padding-bottom: 97px;
}

.pl-97 {
  padding-left: 97px;
}

.p-97 {
  padding: 97px;
}

.pt-98 {
  padding-top: 98px;
}

.pr-98 {
  padding-right: 98px;
}

.pb-98 {
  padding-bottom: 98px;
}

.pl-98 {
  padding-left: 98px;
}

.p-98 {
  padding: 98px;
}

.pt-99 {
  padding-top: 99px;
}

.pr-99 {
  padding-right: 99px;
}

.pb-99 {
  padding-bottom: 99px;
}

.pl-99 {
  padding-left: 99px;
}

.p-99 {
  padding: 99px;
}

.pt-100 {
  padding-top: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.p-100 {
  padding: 100px;
}

.fs-1 {
  font-size: 1px;
}

.fs-2 {
  font-size: 2px;
}

.fs-3 {
  font-size: 3px;
}

.fs-4 {
  font-size: 4px;
}

.fs-5 {
  font-size: 5px;
}

.fs-6 {
  font-size: 6px;
}

.fs-7 {
  font-size: 7px;
}

.fs-8 {
  font-size: 8px;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-23 {
  font-size: 23px;
}

.fs-24 {
  font-size: 24px;
}

.fs-25 {
  font-size: 25px;
}

.fs-26 {
  font-size: 26px;
}

.fs-27 {
  font-size: 27px;
}

.fs-28 {
  font-size: 28px;
}

.fs-29 {
  font-size: 29px;
}

.fs-30 {
  font-size: 30px;
}

.fs-31 {
  font-size: 31px;
}

.fs-32 {
  font-size: 32px;
}

.fs-33 {
  font-size: 33px;
}

.fs-34 {
  font-size: 34px;
}

.fs-35 {
  font-size: 35px;
}

.fs-36 {
  font-size: 36px;
}

.fs-37 {
  font-size: 37px;
}

.fs-38 {
  font-size: 38px;
}

.fs-39 {
  font-size: 39px;
}

.fs-40 {
  font-size: 40px;
}

.w-1 {
  width: 1%;
}

.w-2 {
  width: 2%;
}

.w-3 {
  width: 3%;
}

.w-4 {
  width: 4%;
}

.w-5 {
  width: 5%;
}

.w-6 {
  width: 6%;
}

.w-7 {
  width: 7%;
}

.w-8 {
  width: 8%;
}

.w-9 {
  width: 9%;
}

.w-10 {
  width: 10%;
}

.w-11 {
  width: 11%;
}

.w-12 {
  width: 12%;
}

.w-13 {
  width: 13%;
}

.w-14 {
  width: 14%;
}

.w-15 {
  width: 15%;
}

.w-16 {
  width: 16%;
}

.w-17 {
  width: 17%;
}

.w-18 {
  width: 18%;
}

.w-19 {
  width: 19%;
}

.w-20 {
  width: 20%;
}

.w-21 {
  width: 21%;
}

.w-22 {
  width: 22%;
}

.w-23 {
  width: 23%;
}

.w-24 {
  width: 24%;
}

.w-25 {
  width: 25%;
}

.w-26 {
  width: 26%;
}

.w-27 {
  width: 27%;
}

.w-28 {
  width: 28%;
}

.w-29 {
  width: 29%;
}

.w-30 {
  width: 30%;
}

.w-31 {
  width: 31%;
}

.w-32 {
  width: 32%;
}

.w-33 {
  width: 33%;
}

.w-34 {
  width: 34%;
}

.w-35 {
  width: 35%;
}

.w-36 {
  width: 36%;
}

.w-37 {
  width: 37%;
}

.w-38 {
  width: 38%;
}

.w-39 {
  width: 39%;
}

.w-40 {
  width: 40%;
}

.w-41 {
  width: 41%;
}

.w-42 {
  width: 42%;
}

.w-43 {
  width: 43%;
}

.w-44 {
  width: 44%;
}

.w-45 {
  width: 45%;
}

.w-46 {
  width: 46%;
}

.w-47 {
  width: 47%;
}

.w-48 {
  width: 48%;
}

.w-49 {
  width: 49%;
}

.w-50 {
  width: 50%;
}

.w-51 {
  width: 51%;
}

.w-52 {
  width: 52%;
}

.w-53 {
  width: 53%;
}

.w-54 {
  width: 54%;
}

.w-55 {
  width: 55%;
}

.w-56 {
  width: 56%;
}

.w-57 {
  width: 57%;
}

.w-58 {
  width: 58%;
}

.w-59 {
  width: 59%;
}

.w-60 {
  width: 60%;
}

.w-61 {
  width: 61%;
}

.w-62 {
  width: 62%;
}

.w-63 {
  width: 63%;
}

.w-64 {
  width: 64%;
}

.w-65 {
  width: 65%;
}

.w-66 {
  width: 66%;
}

.w-67 {
  width: 67%;
}

.w-68 {
  width: 68%;
}

.w-69 {
  width: 69%;
}

.w-70 {
  width: 70%;
}

.w-71 {
  width: 71%;
}

.w-72 {
  width: 72%;
}

.w-73 {
  width: 73%;
}

.w-74 {
  width: 74%;
}

.w-75 {
  width: 75%;
}

.w-76 {
  width: 76%;
}

.w-77 {
  width: 77%;
}

.w-78 {
  width: 78%;
}

.w-79 {
  width: 79%;
}

.w-80 {
  width: 80%;
}

.w-81 {
  width: 81%;
}

.w-82 {
  width: 82%;
}

.w-83 {
  width: 83%;
}

.w-84 {
  width: 84%;
}

.w-85 {
  width: 85%;
}

.w-86 {
  width: 86%;
}

.w-87 {
  width: 87%;
}

.w-88 {
  width: 88%;
}

.w-89 {
  width: 89%;
}

.w-90 {
  width: 90%;
}

.w-91 {
  width: 91%;
}

.w-92 {
  width: 92%;
}

.w-93 {
  width: 93%;
}

.w-94 {
  width: 94%;
}

.w-95 {
  width: 95%;
}

.w-96 {
  width: 96%;
}

.w-97 {
  width: 97%;
}

.w-98 {
  width: 98%;
}

.w-99 {
  width: 99%;
}

.w-100 {
  width: 100%;
}

.gap-1 {
  gap: 1px;
}

.gap-2 {
  gap: 2px;
}

.gap-3 {
  gap: 3px;
}

.gap-4 {
  gap: 4px;
}

.gap-5 {
  gap: 5px;
}

.gap-6 {
  gap: 6px;
}

.gap-7 {
  gap: 7px;
}

.gap-8 {
  gap: 8px;
}

.gap-9 {
  gap: 9px;
}

.gap-10 {
  gap: 10px;
}

.gap-11 {
  gap: 11px;
}

.gap-12 {
  gap: 12px;
}

.gap-13 {
  gap: 13px;
}

.gap-14 {
  gap: 14px;
}

.gap-15 {
  gap: 15px;
}

.gap-16 {
  gap: 16px;
}

.gap-17 {
  gap: 17px;
}

.gap-18 {
  gap: 18px;
}

.gap-19 {
  gap: 19px;
}

.gap-20 {
  gap: 20px;
}

.gap-21 {
  gap: 21px;
}

.gap-22 {
  gap: 22px;
}

.gap-23 {
  gap: 23px;
}

.gap-24 {
  gap: 24px;
}

.gap-25 {
  gap: 25px;
}

.gap-26 {
  gap: 26px;
}

.gap-27 {
  gap: 27px;
}

.gap-28 {
  gap: 28px;
}

.gap-29 {
  gap: 29px;
}

.gap-30 {
  gap: 30px;
}

.gap-31 {
  gap: 31px;
}

.gap-32 {
  gap: 32px;
}

.gap-33 {
  gap: 33px;
}

.gap-34 {
  gap: 34px;
}

.gap-35 {
  gap: 35px;
}

.gap-36 {
  gap: 36px;
}

.gap-37 {
  gap: 37px;
}

.gap-38 {
  gap: 38px;
}

.gap-39 {
  gap: 39px;
}

.gap-40 {
  gap: 40px;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.f-bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.padding-10 {
  padding: 10px;
}

.max-height-200 {
  max-height: 200px;
}

.gray-border-1px {
  border: 1px solid #ddd;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-32 {
  gap: 32px;
}

.transparent {
  background-color: transparent;
}

.text-nowrap {
  text-wrap: nowrap;
}

.color-primary {
  color: var(--primary);
}

.color-primary-2 {
  color: var(--primary-2);
}

.color-gray {
  color: var(--gray-color);
}

.color-dark-gray {
  color: var(--dark-gray-color);
}

.color-white {
  color: var(--white);
}

.text-center {
  text-align: center;
}

.border-blue-color-1 {
  border-color: var(--blue-color-1) !important;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-top {
  border-top: 1px solid var(--light-color-2);
}

.border-bottom {
  border-bottom: 1px solid var(--light-color-2);
}

.background-light-dark {
  background-color: var(--light-dark-color);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.line-through {
  text-decoration: line-through;
}

.pointer-events-none {
  pointer-events: none;
  user-select: none;
}

.right-border {
  border-right: 1px solid var(--border-color);
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.right-0 {
  right: 0;
}

.text-align-right {
  text-align: right;
}

p-dialog .p-dialog,
p-confirmdialog .p-dialog {
  background-color: var(--white);
}
p-dialog .p-dialog .p-dialog-header,
p-confirmdialog .p-dialog .p-dialog-header {
  border-bottom: 1px solid var(--border-color);
  padding: 12px var(--lg-space);
}
p-dialog .p-dialog .p-dialog-header .header,
p-confirmdialog .p-dialog .p-dialog-header .header {
  font-weight: bold;
  font-size: 20px;
}
p-dialog .p-dialog .p-dialog-header .p-dialog-header-icon,
p-confirmdialog .p-dialog .p-dialog-header .p-dialog-header-icon {
  color: var(--base-color);
  width: unset;
}
p-dialog .p-dialog .p-dialog-header .p-dialog-header-icon:focus,
p-confirmdialog .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}
p-dialog .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover,
p-confirmdialog .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  background-color: transparent;
}
p-dialog .p-dialog .p-dialog-content,
p-confirmdialog .p-dialog .p-dialog-content {
  padding: 12px var(--lg-space);
  align-items: flex-start;
  position: relative;
  color: var(--base-color);
}
p-dialog .p-dialog .p-dialog-content .p-confirm-dialog-message,
p-confirmdialog .p-dialog .p-dialog-content .p-confirm-dialog-message {
  font-weight: 500;
  margin-left: 0;
}
p-dialog .p-dialog .p-dialog-content .p-confirm-dialog-icon.icon-error,
p-confirmdialog .p-dialog .p-dialog-content .p-confirm-dialog-icon.icon-error {
  margin-right: 9px;
  font-size: 21px;
}
p-dialog .p-dialog .p-dialog-content .p-confirm-dialog-icon.icon-error:before,
p-confirmdialog .p-dialog .p-dialog-content .p-confirm-dialog-icon.icon-error:before {
  color: var(--primary-4);
}
p-dialog .p-dialog .p-dialog-content .dialog-txt,
p-confirmdialog .p-dialog .p-dialog-content .dialog-txt {
  display: flex;
}
p-dialog .p-dialog .p-dialog-content .dialog-txt em,
p-confirmdialog .p-dialog .p-dialog-content .dialog-txt em {
  margin-right: 9px;
  font-size: 21px;
}
p-dialog .p-dialog .p-dialog-content .dialog-txt em:before,
p-confirmdialog .p-dialog .p-dialog-content .dialog-txt em:before {
  color: var(--primary-4);
}
p-dialog .p-dialog .p-dialog-content .viewMode .p-inputtext,
p-confirmdialog .p-dialog .p-dialog-content .viewMode .p-inputtext {
  padding-left: 0;
}
p-dialog .p-dialog .p-dialog-footer,
p-confirmdialog .p-dialog .p-dialog-footer {
  border-top: 1px solid var(--border-color);
  padding: 12px 24px;
}
p-dialog .p-dialog .p-dialog-footer button,
p-confirmdialog .p-dialog .p-dialog-footer button {
  margin-right: 0;
}
p-dialog .p-dialog .p-dialog-footer button ~ button,
p-confirmdialog .p-dialog .p-dialog-footer button ~ button {
  margin-left: 16px;
}

p-dropdown {
  position: relative;
}
p-dropdown .p-dropdown {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  width: 100%;
  background: var(--white);
  transition: none;
  position: static;
}
p-dropdown .p-dropdown .p-dropdown-label {
  padding: 16px;
  font-size: var(--base-fontsize);
  color: var(--base-color);
}
p-dropdown .p-dropdown .p-dropdown-label.p-placeholder {
  color: var(--placeholder-color);
}
p-dropdown .p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--border-focus-color);
  box-shadow: none;
}
p-dropdown .p-dropdown:not(.p-disabled).p-focus:hover {
  border-color: var(--border-focus-color);
}
p-dropdown .p-dropdown:not(.p-disabled):hover {
  border-color: var(--border-color);
}
p-dropdown .p-dropdown .p-dropdown-trigger-icon {
  font-size: var(--sm-fontsize);
  color: var(--light-color);
  height: 14px;
}
p-dropdown .p-dropdown .p-dropdown-clear-icon {
  width: 12px;
  height: 12px;
  color: var(--light-color);
  margin-top: -0.5rem;
}
p-dropdown .p-dropdown.p-disabled {
  border-color: var(--form-bg);
}
p-dropdown .p-dropdown.p-disabled .p-dropdown-label {
  color: var(--disabled-color);
}
p-dropdown .p-dropdown.p-disabled .p-dropdown-label.p-placeholder {
  color: transparent;
}
p-dropdown .p-dropdown.p-disabled .p-dropdown-label.p-placeholder ~ .p-dropdown-trigger {
  display: none;
}
p-dropdown .p-dropdown.p-disabled .p-dropdown-trigger-icon {
  color: var(--disabled-color);
}
p-dropdown.error-messages .p-dropdown {
  border-color: var(--danger-color) !important;
}
p-dropdown.error-messages .p-dropdown .p-dropdown-filter-container .p-dropdown-filter {
  border-color: var(--border-color) !important;
}
p-dropdown.error-messages:after {
  right: 34px;
}
p-dropdown.error-messages + app-show-control-errors {
  display: block;
}
p-dropdown .p-overlay {
  max-width: 100%;
}
p-dropdown + app-show-control-errors {
  display: none;
}
p-dropdown.link:hover .p-dropdown .p-dropdown-label {
  color: var(--hover-primary);
}

.viewMode .p-dropdown {
  border: 0;
  background-color: transparent;
  pointer-events: none;
  user-select: auto;
}
.viewMode .p-dropdown .p-dropdown-label {
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
  padding: 0;
}
.viewMode .p-dropdown .p-dropdown-trigger,
.viewMode .p-dropdown .p-icon-wrapper {
  display: none;
}
.viewMode .p-dropdown .p-dropdown-label.p-placeholder {
  display: none;
}
.viewMode .p-dropdown.p-disabled .p-dropdown-label {
  color: var(--base-color);
}
.viewMode .p-dropdown.p-disabled .p-dropdown-trigger-icon {
  color: var(--base-color);
}

.p-overlay-content {
  margin: 3px 0;
}
.p-overlay-content .p-dropdown-panel {
  box-shadow: none;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  overflow: hidden;
}
.p-overlay-content .p-dropdown-panel .p-dropdown-items-wrapper {
  border-radius: var(--border-radius);
}
.p-overlay-content .p-dropdown-panel .p-dropdown-header {
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  padding: 6px 16px 0;
  border-bottom: 0;
  background-color: transparent;
}
.p-overlay-content .p-dropdown-panel .p-dropdown-header input {
  padding: 5px 16px;
}
.p-overlay-content .p-dropdown-panel .p-dropdown-items {
  padding: 8px 0;
}
.p-overlay-content .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: var(--base-color);
  padding: 7px var(--md-space);
  white-space: normal;
}
.p-overlay-content .p-dropdown-panel .p-dropdown-items .p-dropdown-item:has(.optionDisabled) {
  opacity: 0.5;
  pointer-events: none;
}
.p-overlay-content .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-disabled {
  opacity: 0.5;
}
.p-overlay-content .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  background-color: var(--tab-focus-color);
}
.p-overlay-content .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover, .p-overlay-content .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight, .p-overlay-content .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background-color: var(--hover-color);
}
.p-overlay-content .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  padding: 6px var(--md-space);
  color: var(--base-color);
}
.p-overlay-content .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group ~ p-dropdownitem .p-dropdown-item {
  padding: 6px var(--lg-space);
}
.p-overlay-content .p-dropdown-empty-message {
  color: var(--light-color);
  padding: 7px var(--md-space);
}

@media only screen and (max-width: 767px) {
  .viewMode .p-dropdown .p-dropdown-label {
    padding-left: 0;
  }
}
input:-internal-autofill-selected,
input:-webkit-autofill,
textarea:-internal-autofill-selected,
textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px var(--form-bg) inset !important;
}
input:-internal-autofill-selected:hover, input:-internal-autofill-selected:focus, input:-internal-autofill-selected:active,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-internal-autofill-selected:hover,
textarea:-internal-autofill-selected:focus,
textarea:-internal-autofill-selected:active,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--form-bg) inset !important;
}

p-inputnumber {
  width: 100%;
}
p-inputnumber .p-inputnumber {
  width: 100%;
}
p-inputnumber .p-inputnumber .p-inputnumber-button-group button {
  background-color: #F5F5F5;
  border: 1px solid var(--border-color);
  color: var(--dark-gray-color);
}
p-inputnumber .p-inputnumber .p-inputnumber-button-group button:hover {
  background-color: white;
}

.p-inputtext:not(.p-dropdown-label) {
  border: 1px solid var(--border-color);
  border-radius: var(--sm-border-radius);
  padding: 7px 15px;
  font-size: var(--base-fontsize);
  width: 100%;
  color: var(--base-color);
  background-color: var(--white);
  font-family: var(--font-family);
}
.p-inputtext:not(.p-dropdown-label)::-webkit-input-placeholder {
  color: var(--placeholder-color);
}
.p-inputtext:not(.p-dropdown-label)::-moz-placeholder {
  color: var(--placeholder-color);
}
.p-inputtext:not(.p-dropdown-label):-ms-input-placeholder {
  color: var(--placeholder-color);
}
.p-inputtext:not(.p-dropdown-label):-moz-placeholder {
  color: var(--placeholder-color);
}
.p-inputtext:not(.p-dropdown-label):disabled::-webkit-input-placeholder {
  color: transparent !important;
}
.p-inputtext:not(.p-dropdown-label):disabled::-moz-placeholder {
  color: transparent !important;
}
.p-inputtext:not(.p-dropdown-label):disabled:-ms-input-placeholder {
  color: transparent !important;
}
.p-inputtext:not(.p-dropdown-label):disabled:-moz-placeholder {
  color: transparent !important;
}
.p-inputtext:not(.p-dropdown-label):enabled:hover {
  border-color: var(--border-color);
}
.p-inputtext:not(.p-dropdown-label):enabled:focus {
  box-shadow: none;
  border-color: var(--border-focus-color);
}
.p-inputtext:not(.p-dropdown-label).p-disabled, .p-inputtext:not(.p-dropdown-label).p-component:disabled {
  opacity: 1;
  border-color: var(--form-bg);
  color: var(--disabled-color);
}
.p-inputtext:not(.p-dropdown-label).p-disabled::-webkit-input-placeholder, .p-inputtext:not(.p-dropdown-label).p-component:disabled::-webkit-input-placeholder {
  color: var(--disabled-color);
}
.p-inputtext:not(.p-dropdown-label).p-disabled::-moz-placeholder, .p-inputtext:not(.p-dropdown-label).p-component:disabled::-moz-placeholder {
  color: var(--disabled-color);
}
.p-inputtext:not(.p-dropdown-label).p-disabled:-ms-input-placeholder, .p-inputtext:not(.p-dropdown-label).p-component:disabled:-ms-input-placeholder {
  color: var(--disabled-color);
}
.p-inputtext:not(.p-dropdown-label).p-disabled:-moz-placeholder, .p-inputtext:not(.p-dropdown-label).p-component:disabled:-moz-placeholder {
  color: var(--disabled-color);
}
.p-inputtext.p-inputtextarea {
  min-height: 33px;
}
.p-inputtext.ng-dirty.ng-invalid {
  border-color: var(--danger-color);
}

.p-input-icon-left {
  display: flex;
  align-items: center;
}
.p-input-icon-left em {
  left: 15px;
  position: absolute;
  color: #787f90;
  font-size: var(--fontsize);
}
.p-input-icon-left .p-inputtext {
  padding-left: 40px;
}

.p-input-icon-right {
  display: flex;
  align-items: center;
}
.p-input-icon-right em {
  right: 15px;
  position: absolute;
  color: #787f90;
  font-size: var(--fontsize);
}
.p-input-icon-right .p-inputtext {
  padding-right: 40px;
}

.field {
  margin-bottom: var(--md-space);
}

form .field.grid > label {
  display: inline-block;
  margin-top: 7px;
}
form.search-form p-dropdown .p-dropdown,
form.search-form input {
  background-color: var(--white);
}
form.search-form p-dropdown .p-dropdown:not(.p-dropdown-label),
form.search-form input:not(.p-dropdown-label) {
  background-color: var(--white);
}

.field.grid > label {
  display: inline-block;
  margin-top: 7px;
}
.field.grid > label em {
  font-size: var(--md-fontsize);
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
  cursor: pointer;
}

.validation-control-error {
  color: var(--danger-color);
  font-size: var(--sm-fontsize);
  margin-top: 5px;
  display: block;
}

.p-inputwrapper.ng-invalid.ng-touched,
.p-inputwrapper.ng-invalid.ng-dirty,
.has-error-inputtext:has(.ng-invalid.ng-dirty),
.has-error-inputtext:has(.ng-invalid.ng-touched) {
  position: relative;
  display: flex;
}
.p-inputwrapper.ng-invalid.ng-touched .p-inputtext,
.p-inputwrapper.ng-invalid.ng-dirty .p-inputtext,
.has-error-inputtext:has(.ng-invalid.ng-dirty) .p-inputtext,
.has-error-inputtext:has(.ng-invalid.ng-touched) .p-inputtext {
  border-color: var(--danger-color) !important;
  padding-right: 34px;
}

.viewMode .p-inputwrapper.ng-invalid.ng-touched .p-inputtext,
.viewMode .p-inputwrapper.ng-invalid.ng-dirty .p-inputtext,
.viewMode .has-error-inputtext:has(.ng-invalid.ng-dirty) .p-inputtext,
.viewMode .has-error-inputtext:has(.ng-invalid.ng-touched) .p-inputtext {
  border-color: transparent !important;
}
.viewMode .p-inputtext {
  border-color: transparent !important;
  background-color: transparent;
  pointer-events: none;
  padding: 0;
}
.viewMode .p-inputtext::-webkit-input-placeholder {
  color: transparent;
}
.viewMode .p-inputtext::-moz-placeholder {
  color: transparent;
}
.viewMode .p-inputtext:-ms-input-placeholder {
  color: transparent;
}
.viewMode .p-inputtext:-moz-placeholder {
  color: transparent;
}
.viewMode .icon-calendar {
  display: none;
}
.viewMode input:-internal-autofill-selected,
.viewMode textarea:-internal-autofill-selected,
.viewMode input:-webkit-autofill,
.viewMode textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.viewMode input:-internal-autofill-selected:hover, .viewMode input:-internal-autofill-selected:focus, .viewMode input:-internal-autofill-selected:active,
.viewMode textarea:-internal-autofill-selected:hover,
.viewMode textarea:-internal-autofill-selected:focus,
.viewMode textarea:-internal-autofill-selected:active,
.viewMode input:-webkit-autofill:hover,
.viewMode input:-webkit-autofill:focus,
.viewMode input:-webkit-autofill:active,
.viewMode textarea:-webkit-autofill:hover,
.viewMode textarea:-webkit-autofill:focus,
.viewMode textarea:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.viewMode .validation-control-error {
  display: none;
}
.viewMode .p-disabled,
.viewMode .p-component:disabled,
.viewMode .p-inputtext:not(.p-dropdown-label).p-disabled,
.viewMode .p-inputtext:not(.p-dropdown-label).p-component:disabled {
  color: var(--base-color);
}
.viewMode .p-disabled::-webkit-input-placeholder,
.viewMode .p-component:disabled::-webkit-input-placeholder,
.viewMode .p-inputtext:not(.p-dropdown-label).p-disabled::-webkit-input-placeholder,
.viewMode .p-inputtext:not(.p-dropdown-label).p-component:disabled::-webkit-input-placeholder {
  color: var(--base-color);
}
.viewMode .p-disabled::-moz-placeholder,
.viewMode .p-component:disabled::-moz-placeholder,
.viewMode .p-inputtext:not(.p-dropdown-label).p-disabled::-moz-placeholder,
.viewMode .p-inputtext:not(.p-dropdown-label).p-component:disabled::-moz-placeholder {
  color: var(--base-color);
}
.viewMode .p-disabled:-ms-input-placeholder,
.viewMode .p-component:disabled:-ms-input-placeholder,
.viewMode .p-inputtext:not(.p-dropdown-label).p-disabled:-ms-input-placeholder,
.viewMode .p-inputtext:not(.p-dropdown-label).p-component:disabled:-ms-input-placeholder {
  color: var(--base-color);
}
.viewMode .p-disabled:-moz-placeholder,
.viewMode .p-component:disabled:-moz-placeholder,
.viewMode .p-inputtext:not(.p-dropdown-label).p-disabled:-moz-placeholder,
.viewMode .p-inputtext:not(.p-dropdown-label).p-component:disabled:-moz-placeholder {
  color: var(--base-color);
}

@media only screen and (max-width: 767px) {
  .viewMode .p-inputtext {
    padding-left: 0;
  }
}
p-multiselect {
  position: relative;
  font-size: var(--base-fontsize);
}
p-multiselect .p-multiselect {
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  min-height: 33px;
  background: var(--white);
  position: static;
}
p-multiselect .p-multiselect .p-multiselect-label-container {
  display: flex;
  padding: 16px 0px 16px 16px;
}
p-multiselect .p-multiselect .p-multiselect-label {
  color: var(--base-color);
  display: flex;
  align-items: center;
  height: 100%;
}
p-multiselect .p-multiselect .p-multiselect-label.p-placeholder {
  color: var(--placeholder-color);
  padding-left: 15px;
}
p-multiselect .p-multiselect:not(.p-disabled):hover {
  border-color: var(--border-color);
}
p-multiselect .p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}
p-multiselect .p-multiselect:not(.p-disabled).p-focus, p-multiselect .p-multiselect.p-multiselect-open {
  border-color: var(--border-focus-color);
}
p-multiselect .p-multiselect:not(.p-disabled).p-focus:hover, p-multiselect .p-multiselect.p-multiselect-open:hover {
  border-color: var(--border-focus-color);
}
p-multiselect .p-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
  font-size: var(--sm-fontsize);
  height: 15px;
}
p-multiselect .p-multiselect.p-multiselect-chip .p-multiselect-label {
  display: flex;
  padding-left: 15px;
  gap: 4px;
}
p-multiselect .p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 1px 12px;
  background: var(--light-color-2);
  color: var(--base-color);
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  max-width: 100%;
  display: flex;
  flex: inherit;
  min-width: 0;
}
p-multiselect .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-label {
  min-width: 0;
  flex: 1;
  max-width: 100%;
}
p-multiselect .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  font-size: 10px;
  margin-left: 5px;
}
p-multiselect .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon .icon-remove:before {
  color: var(--light-color);
}
p-multiselect .p-multiselect .p-multiselect-clear-icon {
  width: 12px;
  height: 12px;
  color: var(--light-color);
  margin-top: -0.5rem;
}
p-multiselect.blank-dropdown .p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items p-multiselectitem:last-child {
  border-top: 1px solid var(--border-color);
  display: block;
  padding-top: 8px;
}
p-multiselect.blank-dropdown .p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items p-multiselectitem:nth-last-child(2) {
  margin-bottom: 8px;
  display: block;
}

.p-multiselect-panel {
  box-shadow: none;
  overflow: hidden;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}
.p-multiselect-panel .p-multiselect-header {
  border-top-right-radius: var(--lg-border-radius);
  border-top-left-radius: var(--lg-border-radius);
  padding: 6px 16px;
  border-bottom: 0;
  background-color: transparent;
}
.p-multiselect-panel .p-multiselect-header .select-txt {
  color: var(--light-color);
  order: 3;
  flex: 1;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  display: none;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  order: 1;
  width: 100%;
  margin-bottom: 8px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  left: 16px;
  margin-top: -0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container input {
  padding: 5px 16px 5px 35px;
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items {
  padding: 0;
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-empty-message {
  padding: 6px 16px;
  color: var(--light-color);
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item {
  color: var(--base-color);
  padding: 6px 16px;
  white-space: normal;
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover, .p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: var(--hover-color);
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
  background-color: var(--tab-focus-color);
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item .p-multiselect-empty-message {
  padding: 6px 16px;
  color: var(--light-color);
}
.p-multiselect-panel .p-checkbox {
  margin-right: 8px;
}
.p-multiselect-panel .p-checkbox .p-checkbox-box {
  border: 1px solid #ced4da !important;
}

p-accordion .p-accordion p-accordiontab .p-accordion-tab {
  border: 0;
  border-top: 2px solid var(--border-color);
}
p-accordion .p-accordion p-accordiontab .p-accordion-tab .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background-color: transparent;
}
p-accordion .p-accordion p-accordiontab .p-accordion-tab .p-accordion-header .btn-outline-secondary {
  margin-right: var(--lg-space);
}
p-accordion .p-accordion p-accordiontab .p-accordion-tab .p-accordion-header a.p-accordion-header-link {
  font-weight: 700;
  font-size: var(--lg-fontsize);
  color: var(--base-color);
  min-height: 56px;
  background-color: transparent !important;
  border: 0;
  border-radius: var(--sm-border-radius);
}
p-accordion .p-accordion p-accordiontab .p-accordion-tab .p-accordion-header a.p-accordion-header-link .pi {
  font-family: "icomoon", sans-serif;
  font-size: 6px;
}
p-accordion .p-accordion p-accordiontab .p-accordion-tab .p-accordion-header a.p-accordion-header-link .pi.pi-angle-up:before {
  content: "\e919";
}
p-accordion .p-accordion p-accordiontab .p-accordion-tab .p-accordion-header a.p-accordion-header-link .pi.pi-angle-down:before {
  content: "\e91a";
}
p-accordion .p-accordion p-accordiontab .p-accordion-tab .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}
p-accordion .p-accordion p-accordiontab .p-accordion-tab .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, p-accordion .p-accordion p-accordiontab .p-accordion-tab .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  background: var(--hover-color);
  border-color: var(--border-color);
  color: var(--base-color);
}
p-accordion .p-accordion p-accordiontab .p-accordion-tab .p-accordion-content {
  padding: 10px 0 0;
  border: 0;
  color: var(--base-color);
  position: relative;
}
p-accordion .p-accordion p-accordiontab .p-accordion-tab h4 {
  margin-bottom: var(--md-space);
}
p-accordion .p-accordion p-accordiontab .p-accordion-tab:not(.p-accordion-tab-active) .p-accordion-header.p-disabled:not(.p-highlight) {
  opacity: 0.5;
}
p-accordion.accordion-card-style p-accordiontab .p-accordion-tab {
  background-color: white;
  margin-bottom: 10px;
  border-radius: 30px;
  border: 1px solid rgb(156, 156, 156);
  color: var(--base-color);
  padding: 20px 16px 20px 10px;
}
p-accordion.accordion-card-style p-accordiontab .p-accordion-tab .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background-color: transparent !important;
}
p-accordion.accordion-card-style p-accordiontab .p-accordion-tab .p-accordion-header .btn-outline-secondary {
  margin-right: var(--lg-space);
}
p-accordion.accordion-card-style p-accordiontab .p-accordion-tab .p-accordion-header a.p-accordion-header-link {
  font-weight: 700;
  font-size: var(--lg-fontsize);
  color: var(--base-color);
  min-height: 56px;
  background-color: transparent !important;
  border: 0;
  border-radius: var(--sm-border-radius);
}
p-accordion.accordion-card-style p-accordiontab .p-accordion-tab .p-accordion-header a.p-accordion-header-link .pi {
  font-family: "icomoon", sans-serif;
  font-size: 6px;
}
p-accordion.accordion-card-style p-accordiontab .p-accordion-tab .p-accordion-header a.p-accordion-header-link .pi.pi-angle-up:before {
  content: "\e919";
}
p-accordion.accordion-card-style p-accordiontab .p-accordion-tab .p-accordion-header a.p-accordion-header-link .pi.pi-angle-down:before {
  content: "\e91a";
}
p-accordion.accordion-card-style p-accordiontab .p-accordion-tab .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none;
}
p-accordion.accordion-card-style p-accordiontab .p-accordion-tab .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, p-accordion.accordion-card-style p-accordiontab .p-accordion-tab .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  background: var(--hover-color);
  border-color: var(--border-color);
  color: var(--base-color);
}
p-accordion.accordion-card-style p-accordiontab .p-accordion-tab .p-accordion-content {
  padding: 10px 0 0;
  border: 0;
  border-top: 0;
  color: var(--base-color);
  position: relative;
  background: transparent;
}
p-accordion.accordion-card-style p-accordiontab .p-accordion-tab h4 {
  margin-bottom: var(--md-space);
}
p-accordion.accordion-card-style p-accordiontab .p-accordion-tab:not(.p-accordion-tab-active) .p-accordion-header.p-disabled:not(.p-highlight) {
  opacity: 0.5;
}
p-accordion.accardion-dropdown-style p-accordiontab .p-accordion-tab {
  border-top: none;
  box-shadow: none;
  margin-bottom: 0;
}
p-accordion.accardion-dropdown-style p-accordiontab .p-accordion-tab .p-accordion-header {
  border: 1px solid var(--border-color);
  border-radius: var(--sm-border-radius);
  box-shadow: none;
  min-width: 350px;
}
p-accordion.accardion-dropdown-style p-accordiontab .p-accordion-tab .p-accordion-header a.p-accordion-header-link {
  border-top: none;
  box-shadow: none;
  font-weight: 400;
  font-size: 16px;
  min-height: 30px;
  padding: 4px 16px;
  background-color: var(--white) !important;
}
p-accordion.accardion-dropdown-style p-accordiontab .p-accordion-tab .p-accordion-header a.p-accordion-header-link .p-icon-wrapper .p-icon {
  height: 14px;
  width: 16px;
  transform: rotate(90deg);
  color: #6c757d;
}
p-accordion.accardion-dropdown-style p-accordiontab .p-accordion-tab .p-accordion-content {
  background: transparent;
  padding: 0px;
}
p-accordion.accardion-dropdown-style p-accordiontab .p-accordion-tab.p-accordion-tab-active .p-accordion-header a.p-accordion-header-link .p-icon-wrapper .p-icon {
  transform: none;
}
p-accordion.accardion-dropdown-style p-accordiontab .p-accordion-tab .p-toggable-content .p-accordion-content {
  padding: 0;
}

p-card {
  display: block;
}
p-card .p-card {
  border: 1px solid var(--light-border-color);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: var(--base-color);
}
.voyage-list p-card .p-card {
  height: 100%;
}
p-card .p-card .p-card-title {
  font-weight: 700;
  font-size: var(--lg-fontsize);
  margin-bottom: var(--md-space);
}
p-card .p-card .p-card-header {
  padding: var(--md-space);
}
p-card .p-card .p-card-body {
  padding: var(--md-space);
}
p-card .p-card .p-card-body .p-card-content {
  padding: 0;
}

p-menu .p-menu-list {
  box-shadow: 0px 6px 8px -2px rgba(34, 34, 34, 0.1019607843);
}
p-menu .p-menu-list .p-menuitem .p-menuitem-content {
  border: 0;
  padding: 8px 16px;
}

.vertical-tab {
  display: flex;
  margin-bottom: var(--lg-space);
}
.vertical-tab .p-tabview-nav-container {
  min-width: 170px;
  width: 17%;
  position: relative;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav {
  display: flex;
  flex-direction: column;
  border: 0;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li {
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link {
  border-radius: 0;
  margin: 0;
  border: 0 !important;
  color: var(--base-color);
  font-size: var(--md-fontsize);
  padding: 6px 16px;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link span,
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link app-title {
  font-weight: normal;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link span.invalid,
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link app-title.invalid {
  opacity: 0.5;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link app-title {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link app-title span:first-child {
  flex: 1;
  min-width: 0;
  padding-right: 10px;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link app-title .measure-items:not(.measureStatus) span:first-child {
  max-width: calc(100% - 25px);
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link .icon-delete {
  padding-left: 5px;
  font-size: var(--md-fontsize);
  margin-left: auto;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link .icon-delete:disabled {
  opacity: 0.4;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link .icon-error,
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li .p-tabview-nav-link .icon-check {
  padding-left: 6px;
  margin-left: auto;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li.p-highlight {
  position: relative;
  z-index: 1;
  border-right: 0 !important;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: #F5F8F9;
  color: #D6002A;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li.p-highlight .p-tabview-nav-link .chevron {
  background-color: #D6002A;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li.p-disabled {
  opacity: 0.4;
}
.vertical-tab .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav li.p-tabview-ink-bar {
  border: 0;
}
.vertical-tab .p-tabview-panels {
  flex: 1;
  padding: var(--lg-space) var(--lg-space) var(--lg-space) 40px;
  border: none;
  min-width: 0;
  color: var(--base-color);
}
.vertical-tab .p-tabview-panels p-tabpanel[ng-reflect-disabled=true] {
  opacity: 0.4;
}

.horizontal-tab .p-tabview-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.horizontal-tab .p-tabview-nav li {
  border-bottom: 1px solid var(--border-color);
  border-top: 2px solid transparent;
  padding: 0px 10px;
  width: 100%;
}
.horizontal-tab .p-tabview-nav li .p-tabview-nav-link {
  border-radius: 0;
  margin: 0;
  color: var(--base-color);
  font-size: var(--md-fontsize);
  padding: 9px 16px;
  display: flex;
  justify-content: center;
  font-weight: 400;
}
.horizontal-tab .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.horizontal-tab .p-tabview-nav li.p-highlight {
  position: relative;
  z-index: 1;
  border-right: 0 !important;
  border-bottom: 2px solid var(--primary);
}
.horizontal-tab .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: transparent;
  color: var(--primary);
  font-weight: 700;
}
.horizontal-tab .p-tabview-nav li.p-highlight .p-tabview-nav-link .chevron {
  background-color: var(--primary);
}
.horizontal-tab .p-tabview-nav li.p-disabled {
  opacity: 0.4;
}
.horizontal-tab .p-tabview-nav li.p-tabview-ink-bar {
  border: 0;
}

p-chip .p-chip {
  padding: 5px;
  border: 2px solid;
  color: #1C1E41;
  font-weight: bold;
}

p-breadcrumb nav {
  background-color: transparent;
  font-size: 16px;
  line-height: 20px;
  height: 20px;
}
p-breadcrumb nav .p-menuitem-separator {
  margin: 0 3px;
}
p-breadcrumb nav .active .p-menuitem-link .p-menuitem-text {
  color: #00729e;
}

.switch-select {
  background-color: var(--border-color);
  padding: 4px;
  border-radius: var(--md-border-radius);
  display: flex;
  gap: 8px;
}
.switch-select .p-ripple {
  padding: 2px 63px;
  border-radius: var(--md-border-radius);
  font-size: 13px;
  line-height: 20px;
  background-color: transparent;
}
.switch-select .p-ripple.p-highlight {
  background-color: white;
  color: var(--base-color);
}
.switch-select .p-ripple:focus {
  box-shadow: none;
}
.switch-select .p-ripple .p-button-label {
  font-weight: 400;
}

.order-processing-switch-select {
  background-color: var(--border-color);
  border-radius: var(--md-border-radius);
  border: 1px solid #1C1E41;
  display: flex;
}
.order-processing-switch-select .p-ripple {
  padding: 13px 13px;
  font-size: 13px;
  line-height: 20px;
}
.order-processing-switch-select .p-ripple.p-highlight {
  background-color: #1C1E41;
  color: white;
}
.order-processing-switch-select .p-ripple:focus {
  box-shadow: none;
}
.order-processing-switch-select .p-ripple .p-button-label {
  font-weight: 400;
}

.p-checkbox-label {
  cursor: pointer;
}

.p-checkbox {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.p-checkbox .p-checkbox-box {
  width: 16px;
  height: 16px;
}
.p-checkbox .p-checkbox-box {
  border: 1px solid var(--border-color);
  background: var(--white);
  color: #495057;
  border-radius: 4px;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 9px;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--border-color);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
  border-color: var(--base-color);
  background: var(--base-color);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--base-color);
  background: var(--base-color);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none;
}

.viewMode .p-checkbox {
  pointer-events: none;
}

.button-primary {
  border-radius: var(--sm-border-radius);
  text-align: center;
  color: var(--white);
  text-transform: capitalize;
  padding: 5px 16px;
  background: var(--primary);
  display: inline-block;
  font-size: var(--base-fontsize);
  font-weight: 400;
  cursor: pointer;
  border: 1px solid var(--primary);
  min-width: 75px;
  transition: all 0.1s;
}
.button-primary:focus {
  box-shadow: none;
}
.button-primary .p-button-label {
  transition: none;
  font-weight: 400;
}
.button-primary em {
  margin-right: 5px;
  color: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.button-primary .icon-loading {
  margin-right: 8px;
  display: inline-block;
  color: #b6bbc7;
}
.button-primary:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.button-primary:enabled:hover, .button-primary:enabled:active {
  background-color: var(--hover-primary);
  color: var(--white);
  border-color: var(--hover-primary);
}

.btn-outline-secondary {
  border-radius: var(--sm-border-radius);
  text-align: center;
  color: var(--base-color);
  text-transform: capitalize;
  padding: 5px 16px;
  background: transparent;
  display: inline-block;
  font-size: var(--base-fontsize);
  font-weight: 400;
  cursor: pointer;
  border: 1px solid var(--border-color);
  min-width: 75px;
  transition: all 0.1s;
}
.btn-outline-secondary:focus {
  box-shadow: none;
}
.btn-outline-secondary .p-button-label {
  transition: none;
  font-weight: 400;
}
.btn-outline-secondary em {
  margin-right: 5px;
  color: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.btn-outline-secondary .icon-loading {
  margin-right: 8px;
  display: inline-block;
  color: #b6bbc7;
}
.btn-outline-secondary:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.btn-outline-secondary:enabled:hover, .btn-outline-secondary:enabled:active {
  background-color: var(--hover-primary);
  color: var(--white);
  border-color: var(--hover-primary);
}

.btn-outline {
  border-radius: var(--sm-border-radius);
  text-align: center;
  color: var(--base-color);
  text-transform: capitalize;
  padding: 5px 16px;
  background: transparent;
  display: inline-block;
  font-size: var(--base-fontsize);
  font-weight: 400;
  cursor: pointer;
  border: 1px solid var(--border-color);
  min-width: 75px;
  transition: all 0.1s;
}
.btn-outline:focus {
  box-shadow: none;
}
.btn-outline .p-button-label {
  transition: none;
  font-weight: 400;
}
.btn-outline em {
  margin-right: 5px;
  color: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.btn-outline .icon-loading {
  margin-right: 8px;
  display: inline-block;
  color: #b6bbc7;
}
.btn-outline:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.btn-outline:enabled:hover, .btn-outline:enabled:active {
  background-color: var(--hover-secondary);
  color: var(--white);
  border-color: var(--hover-secondary);
}

.btn-secondary {
  border-radius: var(--sm-border-radius);
  text-align: center;
  color: var(--white);
  text-transform: capitalize;
  padding: 5px 16px;
  background: var(--base-color);
  display: inline-block;
  font-size: var(--base-fontsize);
  font-weight: 400;
  cursor: pointer;
  border: 1px solid var(--base-color);
  min-width: 75px;
  transition: all 0.1s;
}
.btn-secondary:focus {
  box-shadow: none;
}
.btn-secondary .p-button-label {
  transition: none;
  font-weight: 400;
}
.btn-secondary em {
  margin-right: 5px;
  color: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.btn-secondary .icon-loading {
  margin-right: 8px;
  display: inline-block;
  color: #b6bbc7;
}
.btn-secondary:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.btn-secondary:enabled:hover, .btn-secondary:enabled:active {
  background-color: var(--hover-secondary);
  color: var(--white);
  border-color: var(--hover-secondary);
}

.btn-white {
  border-radius: var(--sm-border-radius);
  text-align: center;
  color: var(--base-color);
  text-transform: capitalize;
  padding: 5px 16px;
  background: var(--white);
  display: inline-block;
  font-size: var(--base-fontsize);
  font-weight: 400;
  cursor: pointer;
  border: 1px solid var(--border-color);
  min-width: 75px;
  transition: all 0.1s;
}
.btn-white:focus {
  box-shadow: none;
}
.btn-white .p-button-label {
  transition: none;
  font-weight: 400;
}
.btn-white em {
  margin-right: 5px;
  color: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.btn-white .icon-loading {
  margin-right: 8px;
  display: inline-block;
  color: #b6bbc7;
}
.btn-white:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.btn-white:enabled:hover, .btn-white:enabled:active {
  background-color: var(--hover-primary);
  color: var(--white);
  border-color: var(--hover-primary);
}

.btn-text-white {
  border-radius: var(--sm-border-radius);
  text-align: center;
  color: var(--white);
  text-transform: capitalize;
  padding: 5px 16px;
  background: transparent;
  display: inline-block;
  font-size: var(--base-fontsize);
  font-weight: 400;
  cursor: pointer;
  border: none;
  min-width: 75px;
  transition: all 0.1s;
}
.btn-text-white:focus {
  box-shadow: none;
}
.btn-text-white .p-button-label {
  transition: none;
  font-weight: 400;
}
.btn-text-white em {
  margin-right: 5px;
  color: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.btn-text-white .icon-loading {
  margin-right: 8px;
  display: inline-block;
  color: #b6bbc7;
}
.btn-text-white:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.btn-text-white:enabled:hover, .btn-text-white:enabled:active {
  background-color: transparent;
  color: var(--light-color);
  border-color: transparent;
}
.btn-text-white:enabled:hover em, .btn-text-white:enabled:active em {
  background: var(--light-color);
}
.btn-text-white em {
  background: var(--white);
}

.btn-icon {
  cursor: pointer;
  background-color: transparent;
}
.btn-icon.icon-remove:not(:disabled):hover:before, .btn-icon.icon-delete:not(:disabled):hover:before {
  color: var(--hover-primary);
}
.btn-icon.icon-save:not(:disabled):hover:before {
  color: #2b8fe9;
}
.btn-icon.icon-edit:not(:disabled):hover:before {
  color: var(--primary-5) !important;
}

.btn-text {
  border-radius: var(--sm-border-radius);
  text-align: center;
  color: var(--base-color);
  text-transform: capitalize;
  padding: 5px 16px;
  background: transparent;
  display: inline-block;
  font-size: var(--base-fontsize);
  font-weight: 400;
  cursor: pointer;
  border: none;
  min-width: 75px;
  transition: all 0.1s;
}
.btn-text:focus {
  box-shadow: none;
}
.btn-text .p-button-label {
  transition: none;
  font-weight: 400;
}
.btn-text em {
  margin-right: 5px;
  color: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.btn-text .icon-loading {
  margin-right: 8px;
  display: inline-block;
  color: #b6bbc7;
}
.btn-text:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.btn-text:enabled:hover, .btn-text:enabled:active {
  background-color: transparent;
  color: var(--light-color);
  border-color: transparent;
}
.btn-text:enabled:hover em, .btn-text:enabled:active em {
  background: var(--light-color);
}
.btn-text em {
  background: var(--white);
}

.btn-icon-only {
  border-radius: var(--sm-border-radius);
  text-align: center;
  color: var(--base-color);
  text-transform: capitalize;
  padding: 5px 16px;
  background: transparent;
  display: inline-block;
  font-size: var(--base-fontsize);
  font-weight: 400;
  cursor: pointer;
  border: none;
  min-width: 75px;
  transition: all 0.1s;
  padding: 0;
  min-width: 0;
}
.btn-icon-only:focus {
  box-shadow: none;
}
.btn-icon-only .p-button-label {
  transition: none;
  font-weight: 400;
}
.btn-icon-only em {
  margin-right: 5px;
  color: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.btn-icon-only .icon-loading {
  margin-right: 8px;
  display: inline-block;
  color: #b6bbc7;
}
.btn-icon-only:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.btn-icon-only:enabled:hover, .btn-icon-only:enabled:active {
  background-color: transparent;
  color: var(--light-color);
  border-color: transparent;
}
.btn-icon-only:enabled:hover em, .btn-icon-only:enabled:active em {
  background: none;
}

.btn-primary-2 {
  border-radius: var(--sm-border-radius);
  text-align: center;
  color: var(--white);
  text-transform: capitalize;
  padding: 5px 16px;
  background: var(--primary-2);
  display: inline-block;
  font-size: var(--base-fontsize);
  font-weight: 400;
  cursor: pointer;
  border: 1px solid var(--primary-2);
  min-width: 75px;
  transition: all 0.1s;
}
.btn-primary-2:focus {
  box-shadow: none;
}
.btn-primary-2 .p-button-label {
  transition: none;
  font-weight: 400;
}
.btn-primary-2 em {
  margin-right: 5px;
  color: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.btn-primary-2 .icon-loading {
  margin-right: 8px;
  display: inline-block;
  color: #b6bbc7;
}
.btn-primary-2:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.btn-primary-2:enabled:hover, .btn-primary-2:enabled:active {
  background-color: var(--hover-primary);
  color: var(--white);
  border-color: var(--hover-primary);
}

.btn-primary-2-outline {
  border-radius: var(--sm-border-radius);
  text-align: center;
  color: var(--primary-2);
  text-transform: capitalize;
  padding: 5px 16px;
  background: var(--white);
  display: inline-block;
  font-size: var(--base-fontsize);
  font-weight: 400;
  cursor: pointer;
  border: 1px solid var(--primary-2);
  min-width: 75px;
  transition: all 0.1s;
}
.btn-primary-2-outline:focus {
  box-shadow: none;
}
.btn-primary-2-outline .p-button-label {
  transition: none;
  font-weight: 400;
}
.btn-primary-2-outline em {
  margin-right: 5px;
  color: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.btn-primary-2-outline .icon-loading {
  margin-right: 8px;
  display: inline-block;
  color: #b6bbc7;
}
.btn-primary-2-outline:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.btn-primary-2-outline:enabled:hover, .btn-primary-2-outline:enabled:active {
  background-color: var(--primary-2);
  color: var(--white);
  border-color: var(--primary-2);
}

.btn-primary-5 {
  border-radius: var(--sm-border-radius);
  text-align: center;
  color: var(--white);
  text-transform: capitalize;
  padding: 5px 16px;
  background: var(--primary-5);
  display: inline-block;
  font-size: var(--base-fontsize);
  font-weight: 400;
  cursor: pointer;
  border: 1px solid var(--primary-5);
  min-width: 75px;
  transition: all 0.1s;
}
.btn-primary-5:focus {
  box-shadow: none;
}
.btn-primary-5 .p-button-label {
  transition: none;
  font-weight: 400;
}
.btn-primary-5 em {
  margin-right: 5px;
  color: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.btn-primary-5 .icon-loading {
  margin-right: 8px;
  display: inline-block;
  color: #b6bbc7;
}
.btn-primary-5:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.btn-primary-5:enabled:hover, .btn-primary-5:enabled:active {
  background-color: var(--hover-primary);
  color: var(--white);
  border-color: var(--hover-primary);
}

.btn-primary-6 {
  border-radius: var(--sm-border-radius);
  text-align: center;
  color: var(--white);
  text-transform: capitalize;
  padding: 5px 16px;
  background: var(--primary-6);
  display: inline-block;
  font-size: var(--base-fontsize);
  font-weight: 400;
  cursor: pointer;
  border: 1px solid var(--primary-6);
  min-width: 75px;
  transition: all 0.1s;
}
.btn-primary-6:focus {
  box-shadow: none;
}
.btn-primary-6 .p-button-label {
  transition: none;
  font-weight: 400;
}
.btn-primary-6 em {
  margin-right: 5px;
  color: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.btn-primary-6 .icon-loading {
  margin-right: 8px;
  display: inline-block;
  color: #b6bbc7;
}
.btn-primary-6:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.btn-primary-6:enabled:hover, .btn-primary-6:enabled:active {
  background-color: var(--hover-primary);
  color: var(--white);
  border-color: var(--hover-primary);
}

p-dropdown {
  position: relative;
}
p-dropdown .new-version {
  border: 1px solid var(--border-color);
  border-radius: var(--sm-border-radius);
  width: 100%;
  background: var(--white);
  transition: none;
  position: static;
  line-height: 22px;
}
p-dropdown .new-version .p-dropdown-label {
  padding: 4px 16px;
  font-size: var(--md-fontsize);
  color: var(--base-color);
}
p-dropdown .new-version .p-dropdown-label.p-placeholder {
  color: var(--placeholder-color);
}
p-dropdown .new-version:not(.p-disabled).p-focus {
  border-color: var(--border-focus-color);
  box-shadow: none;
}
p-dropdown .new-version:not(.p-disabled).p-focus:hover {
  border-color: var(--border-focus-color);
}
p-dropdown .new-version:not(.p-disabled):hover {
  border-color: var(--border-color);
}
p-dropdown .new-version .p-dropdown-trigger-icon {
  font-size: var(--sm-fontsize);
  color: var(--light-color);
  height: 14px;
}
p-dropdown .new-version .p-dropdown-clear-icon {
  width: 12px;
  height: 12px;
  color: var(--light-color);
  margin-top: -0.5rem;
}
p-dropdown .new-version.p-disabled {
  border-color: var(--form-bg);
}
p-dropdown .new-version.p-disabled .p-dropdown-label {
  color: var(--disabled-color);
}
p-dropdown .new-version.p-disabled .p-dropdown-label.p-placeholder {
  color: transparent;
}
p-dropdown .new-version.p-disabled .p-dropdown-label.p-placeholder ~ .p-dropdown-trigger {
  display: none;
}
p-dropdown .new-version.p-disabled .p-dropdown-trigger-icon {
  color: var(--disabled-color);
}
p-dropdown.ng-invalid.ng-touched .p-dropdown {
  border-color: var(--danger-color) !important;
}
p-dropdown.ng-invalid.ng-touched .p-dropdown .p-dropdown-filter-container .p-dropdown-filter {
  border-color: var(--border-color) !important;
}
p-dropdown.ng-invalid.ng-touched:after {
  right: 34px;
}
p-dropdown .p-overlay {
  max-width: 100%;
}
p-dropdown.link .p-dropdown .p-dropdown-label {
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}
p-dropdown.link .p-dropdown .p-dropdown-label:hover {
  color: var(--hover-primary);
}
p-dropdown.link:hover .p-dropdown .p-dropdown-label {
  color: var(--hover-primary);
}

.new-version.viewMode {
  border: 0;
  background-color: transparent;
  pointer-events: none;
  user-select: auto;
}
.new-version.viewMode .p-dropdown-label {
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
  padding: 0;
}
.new-version.viewMode .p-dropdown-trigger,
.new-version.viewMode .p-icon-wrapper {
  display: none;
}
.new-version.viewMode .p-dropdown-label.p-placeholder {
  display: none;
}
.new-version.viewMode.p-disabled .p-dropdown-label {
  color: var(--base-color);
}
.new-version.viewMode.p-disabled .p-dropdown-trigger-icon {
  color: var(--base-color);
}

.p-overlay-content {
  margin: 3px 0;
}
.p-overlay-content .new-version-panel {
  box-shadow: none;
  border: 2px solid var(--border-color);
  border-radius: var(--md-border-radius);
  overflow: hidden;
}
.p-overlay-content .new-version-panel .p-dropdown-items-wrapper {
  border-radius: var(--md-border-radius);
}
.p-overlay-content .new-version-panel .p-dropdown-header {
  border-top-right-radius: var(--md-border-radius);
  border-top-left-radius: var(--md-border-radius);
  padding: 6px 16px 0;
  border-bottom: 0;
  background-color: transparent;
}
.p-overlay-content .new-version-panel .p-dropdown-items {
  padding: 8px 0;
}
.p-overlay-content .new-version-panel .p-dropdown-items .p-dropdown-item {
  color: var(--base-color);
  padding: 7px var(--md-space);
  white-space: normal;
}
.p-overlay-content .new-version-panel .p-dropdown-items .p-dropdown-item:has(.optionDisabled) {
  opacity: 0.5;
  pointer-events: none;
}
.p-overlay-content .new-version-panel .p-dropdown-items .p-dropdown-item.p-disabled {
  opacity: 0.5;
}
.p-overlay-content .new-version-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  background-color: var(--tab-focus-color);
}
.p-overlay-content .new-version-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover, .p-overlay-content .new-version-panel .p-dropdown-items .p-dropdown-item.p-highlight, .p-overlay-content .new-version-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background-color: var(--hover-color);
}
.p-overlay-content .new-version-panel .p-dropdown-items .p-dropdown-item-group {
  padding: 6px var(--md-space);
  color: var(--base-color);
}
.p-overlay-content .new-version-panel .p-dropdown-items .p-dropdown-item-group ~ p-dropdownitem .p-dropdown-item {
  padding: 6px var(--lg-space);
}
.p-overlay-content .p-dropdown-empty-message {
  color: var(--light-color);
  padding: 7px var(--md-space);
}

@media only screen and (max-width: 767px) {
  .viewMode .p-dropdown .p-dropdown-label {
    padding-left: 0;
  }
}
p-table {
  display: block;
  background: var(--white);
  flex: 1;
}
p-table.admin-table {
  margin-top: 0;
}
p-table .p-datatable {
  display: block;
  font-size: var(--sm-fontsize);
}
p-table .p-datatable .icon-loading {
  color: var(--primary);
  font-size: 48px;
}
p-table .p-datatable table .empty-message {
  font-size: var(--base-fontsize);
}
p-table .p-datatable table > .p-datatable-thead > tr > th {
  border: 1px solid var(--light-color-2);
  border-width: 0 0 1px 0;
  color: var(--light-color);
  padding: 6px 12px;
  background-color: #eeeeee;
  font-size: 13px;
  line-height: 20px;
}
p-table .p-datatable table > .p-datatable-thead > tr > th.p-sortable-column:focus {
  box-shadow: none;
}
p-table .p-datatable table > .p-datatable-thead > tr > th.p-sortable-column .p-icon-wrapper {
  margin-left: 2px;
  color: #909090;
  display: inline-flex;
  font-weight: 400;
  font-family: "icomoon", sans-serif;
}
p-table .p-datatable table > .p-datatable-thead > tr > th.p-sortable-column .p-icon-wrapper:before {
  content: "\e916";
}
p-table .p-datatable table > .p-datatable-thead > tr > th.p-sortable-column .p-icon-wrapper:after {
  content: "\e917";
}
p-table .p-datatable table > .p-datatable-thead > tr > th.p-sortable-column .p-icon-wrapper .p-sortable-column-icon {
  display: none;
}
p-table .p-datatable table > .p-datatable-thead > tr > th.p-sortable-column.p-highlight {
  background-color: var(--white);
  color: var(--light-color);
  box-shadow: none;
}
p-table .p-datatable table > .p-datatable-thead > tr > th.p-sortable-column:not(.p-highlight):hover {
  background-color: var(--white);
  color: var(--light-color);
}
p-table .p-datatable table > .p-datatable-thead > tr > th.p-sortable-column[aria-sort=ascending] .p-icon-wrapper:before {
  color: var(--primary);
}
p-table .p-datatable table > .p-datatable-thead > tr > th.p-sortable-column[aria-sort=descending] .p-icon-wrapper:after {
  color: var(--primary);
}
p-table .p-datatable table .p-datatable-tbody > tr:focus {
  outline: 0;
}
p-table .p-datatable table .p-datatable-tbody > tr:hover {
  background-color: var(--hover-color);
}
p-table .p-datatable table .p-datatable-tbody > tr td {
  border: 1px solid var(--light-color-2);
  border-width: 0 0 1px 0;
  padding: 8px 10px;
  height: 51px;
  color: var(--base-color);
  cursor: pointer;
}
p-table .p-datatable table .p-datatable-tbody > tr td:nth-child(-n+2) > div {
  display: flex;
  align-items: center;
}
p-table .p-datatable table .p-datatable-tbody > tr td:nth-child(-n+2) > div .icon-error {
  margin-left: 6px;
}
p-table .p-datatable table .p-datatable-tbody > tr td.empty-message {
  cursor: auto;
}
p-table .p-datatable table .p-datatable-tbody > tr.cancelled-row {
  position: relative;
}
p-table .p-datatable table .p-datatable-tbody > tr.cancelled-row::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(205, 205, 205, 0.4235294118);
  cursor: not-allowed;
  z-index: 2;
}
p-table .p-datatable table .p-datatable-tbody > tr.cancelled-row > td.p-frozen-column::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(205, 205, 205, 0.4235294118);
  cursor: not-allowed;
  z-index: 2;
  pointer-events: none;
}
p-table .p-datatable table .p-datatable-tbody > tr.cancelled-row td:first-child.p-frozen-column {
  z-index: 4;
}
p-table .p-datatable table .p-datatable-tbody > tr.cancelled-row p-tableCheckbox {
  position: relative;
  z-index: 3;
}
p-table .p-component-overlay {
  background-color: rgba(255, 255, 255, 0.9);
}
p-table.no-cursor .p-datatable table .p-datatable-tbody > tr td {
  cursor: default;
}
p-table .p-datatable-loading-overlay {
  min-height: 500px;
}
p-table.padd-0 .p-datatable {
  padding: 0 0 var(--lg-space);
}
p-table .cell-selected {
  background-color: #E5F3FA;
  color: #0f5132;
}
p-table .p-datatable-cell.selected {
  background-color: #cce5ff;
}
p-table .p-datatable-loading-overlay svg {
  width: 50px;
  height: 50px;
}
p-table ::-webkit-scrollbar {
  width: 8px !important;
  height: 8px;
  display: block !important;
}
p-table ::-webkit-scrollbar-track {
  background: var(--scroll-track-color);
  border-radius: 8px;
}
p-table ::-webkit-scrollbar-thumb {
  background: var(--scroll-color);
  border-radius: 8px;
}
p-table * {
  scrollbar-width: auto !important;
}

p-calendar {
  display: block;
  width: 100%;
}
p-calendar .large-input input {
  padding: 16px !important;
}
p-calendar .p-calendar {
  align-items: center;
  color: var(--base-color);
  width: 100%;
}
p-calendar .p-calendar .p-button {
  color: var(--light-color);
  background: var(--white);
  border: 0;
  padding: 0;
  border-left: 0;
  border-radius: 0;
  position: absolute;
  right: 15px;
  width: unset;
}
p-calendar .p-calendar .p-button:enabled:hover {
  background: transparent;
  color: var(--light-color);
  border-color: var(--border-color);
}
p-calendar .p-calendar .p-button:focus {
  box-shadow: none;
}
p-calendar .p-calendar .p-button .pi-calendar {
  font-size: var(--md-fontsize);
}
p-calendar .p-calendar .p-inputtext {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
p-calendar .p-calendar:not(.p-calendar-disabled).p-focus > .p-inputtext {
  box-shadow: none;
}
p-calendar + app-show-control-errors {
  display: none;
}
p-calendar.ng-dirty.ng-invalid:after, p-calendar.ng-invalid.ng-touched:after {
  display: none;
}
p-calendar.ng-dirty.ng-invalid + app-show-control-errors, p-calendar.ng-invalid.ng-touched + app-show-control-errors {
  display: block;
}

.p-datepicker {
  border-radius: var(--md-border-radius);
  border: 2px solid var(--border-color);
  border: 0;
  min-width: 256px !important;
  max-width: 256px;
  margin: 3px 0;
  padding: 0.5rem;
}
.p-datepicker .p-datepicker-group-container {
  border-radius: var(--md-border-radius);
  background-color: var(--white);
}
.p-datepicker:not(.p-datepicker-inline) {
  border: 2px solid var(--border-color);
  box-shadow: none;
}
.p-datepicker .p-yearpicker,
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-yearpicker-month, .p-datepicker .p-yearpicker-year,
.p-datepicker .p-monthpicker-month,
.p-datepicker .p-monthpicker-year {
  padding: 0.5rem;
}
.p-datepicker .p-yearpicker-month.p-highlight, .p-datepicker .p-yearpicker-year.p-highlight,
.p-datepicker .p-monthpicker-month.p-highlight,
.p-datepicker .p-monthpicker-year.p-highlight {
  color: var(--white);
  background: var(--primary);
}
.p-datepicker .p-datepicker-header {
  border-bottom: 0;
  padding: 0;
  border-radius: var(--md-border-radius) var(--md-border-radius) 0 0;
}
.p-datepicker .p-datepicker-header .p-datepicker-title button.p-link {
  padding: 0;
  color: var(--base-color);
}
.p-datepicker .p-datepicker-header .p-datepicker-title button.p-link:enabled:hover {
  color: var(--base-color);
  background-color: transparent;
}
.p-datepicker .p-datepicker-header .p-datepicker-title button.p-link.p-datepicker-month {
  margin-right: 4px;
}
.p-datepicker .p-datepicker-header .p-datepicker-title button:focus {
  box-shadow: none !important;
}
.p-datepicker table.p-datepicker-calendar {
  margin: 0.5rem 0;
}
.p-datepicker table tr th {
  font-size: 13px;
  color: var(--light-color);
  font-weight: 500;
}
.p-datepicker table td {
  padding: 1px;
  font-size: var(--base-fontsize);
}
.p-datepicker table td > span {
  width: 32px;
  height: 32px;
  color: var(--base-color);
}
.p-datepicker table td > span:focus {
  box-shadow: none;
}
.p-datepicker table td > span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 !important;
  box-shadow: none !important;
  background-color: var(--tab-focus-color);
}
.p-datepicker table td > span.p-highlight {
  color: var(--white);
  background: var(--primary);
}
.p-datepicker table td > span.p-disabled {
  color: var(--disabled-color);
}
.p-datepicker table td.p-datepicker-today span {
  color: var(--primary);
  background-color: var(--hover-color);
}
.p-datepicker table td.p-datepicker-today span.p-highlight {
  color: var(--white);
  background: var(--primary);
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background-color: var(--hover-color);
}
.p-datepicker:not(.p-disabled) .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover, .p-datepicker:not(.p-disabled) .p-monthpicker-year:not(.p-disabled):not(.p-highlight):hover,
.p-datepicker:not(.p-disabled) .p-yearpicker-month:not(.p-disabled):not(.p-highlight):hover,
.p-datepicker:not(.p-disabled) .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: var(--hover-color);
}
.p-datepicker:not(.p-disabled) .p-monthpicker-month:not(.p-disabled):focus, .p-datepicker:not(.p-disabled) .p-monthpicker-year:not(.p-disabled):focus,
.p-datepicker:not(.p-disabled) .p-yearpicker-month:not(.p-disabled):focus,
.p-datepicker:not(.p-disabled) .p-yearpicker-year:not(.p-disabled):focus {
  box-shadow: none;
}

.attachment-style .p-fileupload-row {
  padding-top: 20px;
  display: flex;
  text-align: center;
  align-items: center;
}
.attachment-style .p-fileupload-content {
  display: none;
}
.attachment-style .p-fileupload-content .p-progressbar {
  top: 10px !important;
}
.attachment-style .p-fileupload-buttonbar {
  background-color: transparent;
}
.attachment-style .p-fileupload-buttonbar p-button {
  display: none;
}
.attachment-style .p-progressbar-determinate {
  display: none;
}
.attachment-style .p-fileupload-choose {
  background-color: #d6082a;
  margin-right: 8px;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.attachment-style .p-button-icon-only {
  background-color: transparent;
  padding: 0px !important;
  width: 40px;
  height: 20px;
  border: none;
  color: black;
}

.spinner-style .p-progress-spinner-svg circle {
  stroke: #1c1e41 !important;
}
.spinner-style.p-progress-spinner {
  position: relative;
  margin: 0 auto;
  display: inline-block;
}

.small-spinner-style .p-progress-spinner-svg circle {
  stroke: #1c1e41 !important;
}
.small-spinner-style.p-progress-spinner {
  position: relative;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  display: inline-block;
}

.smaller-spinner-style .p-progress-spinner-svg circle {
  stroke: #ffffff !important;
}
.smaller-spinner-style.p-progress-spinner {
  position: relative;
  margin: 0 auto;
  width: 10px;
  height: 10px;
  display: inline-block;
}

.p-tooltip {
  max-width: 14rem !important;
}
.p-tooltip .p-tooltip-text {
  padding: 8px !important;
}

.p-progressbar .p-progressbar-value {
  background: linear-gradient(to right, #1C1E41, #9c27b0) !important;
}

p-autoComplete {
  position: relative;
}
p-autoComplete .p-autocomplete {
  border: 1px solid var(--border-color);
  border-radius: var(--sm-border-radius);
  width: 100%;
  background: var(--white);
  transition: none;
  position: static;
  line-height: 22px;
}
p-autoComplete .p-autocomplete .p-autocomplete-input {
  padding: 4px 16px;
  font-size: var(--md-fontsize);
  color: var(--base-color);
}
p-autoComplete .p-autocomplete .p-autocomplete-input.p-placeholder {
  color: var(--placeholder-color);
}
p-autoComplete .p-autocomplete:not(.p-disabled).p-focus {
  border-color: var(--border-focus-color);
  box-shadow: none;
}
p-autoComplete .p-autocomplete:not(.p-disabled).p-focus:hover {
  border-color: var(--border-focus-color);
}
p-autoComplete .p-autocomplete:not(.p-disabled):hover {
  border-color: var(--border-color);
}
p-autoComplete .p-autocomplete.p-disabled {
  border-color: var(--form-bg);
}
p-autoComplete .p-autocomplete.p-disabled .p-autocomplete-input {
  color: var(--disabled-color);
}
p-autoComplete .p-autocomplete.p-disabled .p-autocomplete-input.p-placeholder {
  color: transparent;
}
p-autoComplete .p-overlay {
  max-width: 100%;
}
p-autoComplete + app-show-control-errors {
  display: none;
}

.p-autocomplete.viewMode {
  border: 0;
  background-color: transparent;
  pointer-events: none;
  user-select: auto;
}
.p-autocomplete.viewMode .p-autocomplete-input {
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
  padding: 0;
}
.p-autocomplete.viewMode .p-icon-wrapper {
  display: none;
}
.p-autocomplete.viewMode .p-autocomplete-input.p-placeholder {
  display: none;
}
.p-autocomplete.viewMode.p-disabled .p-autocomplete-input {
  color: var(--base-color);
}

.viewMode .p-autocomplete {
  border-color: transparent !important;
  background-color: transparent;
  pointer-events: none;
  padding: 0;
}
.viewMode .p-autocomplete::-webkit-input-placeholder {
  color: transparent;
}
.viewMode .p-autocomplete::-moz-placeholder {
  color: transparent;
}
.viewMode .p-autocomplete:-ms-input-placeholder {
  color: transparent;
}
.viewMode .p-autocomplete:-moz-placeholder {
  color: transparent;
}

.p-overlay-content {
  margin: 3px 0;
}
.p-overlay-content .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  color: var(--base-color);
  padding: 7px var(--md-space);
  white-space: normal;
}
.p-overlay-content .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:has(.optionDisabled) {
  opacity: 0.5;
  pointer-events: none;
}
.p-overlay-content .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-disabled {
  opacity: 0.5;
}
.p-overlay-content .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled).p-focus {
  background-color: var(--tab-focus-color);
}
.p-overlay-content .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:not(.p-highlight):not(.p-disabled):hover, .p-overlay-content .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight, .p-overlay-content .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight.p-focus {
  background-color: var(--hover-color);
}

p-dropdown {
  position: relative;
}
p-dropdown .dropdown-dark {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  width: 100%;
  background: #1D2125;
  transition: none;
  position: static;
}
p-dropdown .dropdown-dark .p-dropdown-label {
  padding: 16px 0px 16px 16px;
  font-size: var(--base-fontsize);
  color: #EEEEEE;
}
p-dropdown .dropdown-dark .p-dropdown-label.p-placeholder {
  color: #EEEEEE;
}
p-dropdown .dropdown-dark:not(.p-disabled).p-focus {
  border-color: var(--border-focus-color);
  box-shadow: none;
}
p-dropdown .dropdown-dark:not(.p-disabled).p-focus:hover {
  border-color: var(--border-focus-color);
}
p-dropdown .dropdown-dark:not(.p-disabled):hover {
  border-color: var(--border-color);
}
p-dropdown .dropdown-dark .p-dropdown-trigger-icon {
  font-size: var(--sm-fontsize);
  color: var(--light-color);
  height: 14px;
}
p-dropdown .dropdown-dark .p-dropdown-clear-icon {
  width: 12px;
  height: 12px;
  color: var(--light-color);
  margin-top: -0.5rem;
}
p-dropdown .dropdown-dark.p-disabled {
  border-color: var(--form-bg);
}
p-dropdown .dropdown-dark.p-disabled .p-dropdown-label {
  color: var(--disabled-color);
}
p-dropdown .dropdown-dark.p-disabled .p-dropdown-label.p-placeholder {
  color: transparent;
}
p-dropdown .dropdown-dark.p-disabled .p-dropdown-label.p-placeholder ~ .p-dropdown-trigger {
  display: none;
}
p-dropdown .dropdown-dark.p-disabled .p-dropdown-trigger-icon {
  color: var(--disabled-color);
}
p-dropdown.error-messages .p-dropdown {
  border-color: var(--danger-color) !important;
}
p-dropdown.error-messages .p-dropdown .p-dropdown-filter-container .p-dropdown-filter {
  border-color: var(--border-color) !important;
}
p-dropdown.error-messages:after {
  right: 34px;
}
p-dropdown.error-messages + app-show-control-errors {
  display: block;
}
p-dropdown .p-overlay {
  max-width: 100%;
}
p-dropdown + app-show-control-errors {
  display: none;
}
p-dropdown.link:hover .p-dropdown .p-dropdown-label {
  color: var(--hover-primary);
}

.viewMode .p-dropdown {
  border: 0;
  background-color: transparent;
  pointer-events: none;
  user-select: auto;
}
.viewMode .p-dropdown .p-dropdown-label {
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
  padding: 0;
}
.viewMode .p-dropdown .p-dropdown-trigger,
.viewMode .p-dropdown .p-icon-wrapper {
  display: none;
}
.viewMode .p-dropdown .p-dropdown-label.p-placeholder {
  display: none;
}
.viewMode .p-dropdown.p-disabled .p-dropdown-label {
  color: var(--base-color);
}
.viewMode .p-dropdown.p-disabled .p-dropdown-trigger-icon {
  color: var(--base-color);
}

.p-overlay-content {
  margin: 3px 0;
}
.p-overlay-content .dropdown-dark-panel {
  box-shadow: none;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  background: #1D2125;
  overflow: hidden;
}
.p-overlay-content .dropdown-dark-panel .p-dropdown-items-wrapper {
  border-radius: var(--border-radius);
}
.p-overlay-content .dropdown-dark-panel .p-dropdown-header {
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  padding: 6px 16px 0;
  border-bottom: 0;
  background-color: transparent;
}
.p-overlay-content .dropdown-dark-panel .p-dropdown-header input {
  padding: 5px 16px;
}
.p-overlay-content .dropdown-dark-panel .p-dropdown-items {
  padding: 8px 0;
}
.p-overlay-content .dropdown-dark-panel .p-dropdown-items .p-dropdown-item {
  color: #EEEEEE;
  padding: 7px var(--md-space);
  white-space: normal;
}
.p-overlay-content .dropdown-dark-panel .p-dropdown-items .p-dropdown-item:has(.optionDisabled) {
  opacity: 0.5;
  pointer-events: none;
}
.p-overlay-content .dropdown-dark-panel .p-dropdown-items .p-dropdown-item.p-disabled {
  opacity: 0.5;
}
.p-overlay-content .dropdown-dark-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus {
  background-color: #88909e;
}
.p-overlay-content .dropdown-dark-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover, .p-overlay-content .dropdown-dark-panel .p-dropdown-items .p-dropdown-item.p-highlight, .p-overlay-content .dropdown-dark-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus {
  background-color: #88909e;
}
.p-overlay-content .dropdown-dark-panel .p-dropdown-items .p-dropdown-item-group {
  padding: 6px var(--md-space);
  color: var(--base-color);
}
.p-overlay-content .dropdown-dark-panel .p-dropdown-items .p-dropdown-item-group ~ p-dropdownitem .p-dropdown-item {
  padding: 6px var(--lg-space);
}
.p-overlay-content .p-dropdown-empty-message {
  color: var(--light-color);
  padding: 7px var(--md-space);
}

@media only screen and (max-width: 767px) {
  .viewMode .p-dropdown .p-dropdown-label {
    padding-left: 0;
  }
}
.p-steps .p-steps-item .p-menuitem-link {
  cursor: pointer;
  margin-bottom: 10px;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #465668;
  background: #fff;
  border: 1px solid #465668;
  margin-bottom: 5px;
}
.p-steps .p-steps-item::before {
  border-top: 1px dotted #A1AEBE;
  left: -48%;
}
.p-steps .p-steps-item.p-highlight .p-menuitem-link .p-steps-number, .p-steps .p-steps-item.passed .p-menuitem-link .p-steps-number {
  color: var(--primary);
  border-color: var(--primary);
}
.p-steps .p-steps-item.p-highlight .p-menuitem-link .p-steps-title, .p-steps .p-steps-item.passed .p-menuitem-link .p-steps-title {
  font-weight: 400;
  color: var(--primary);
}
.p-steps .p-steps-item.p-highlight .p-menuitem-link {
  cursor: default;
}
.p-steps .p-steps-item.p-highlight .p-menuitem-link .p-steps-number {
  font-weight: 600;
}
.p-steps .p-steps-item.p-highlight .p-menuitem-link .p-steps-title {
  font-weight: 600;
}
.p-steps .p-steps-item.passed::before {
  border-top: 1px solid var(--primary);
}
.p-steps .p-steps-item:first-child::before {
  border-top: none;
}

p-multiselect {
  position: relative;
  font-size: var(--md-fontsize);
}
p-multiselect .new-version-multiselect {
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: var(--sm-border-radius);
  min-height: 32px;
  background: var(--white);
  position: static;
}
p-multiselect .new-version-multiselect .p-multiselect-label-container {
  display: flex;
  padding: 4px 16px;
}
p-multiselect .new-version-multiselect .p-multiselect-label {
  color: var(--base-color);
  display: flex;
  align-items: center;
  height: 100%;
}
p-multiselect .new-version-multiselect .p-multiselect-label.p-placeholder {
  color: var(--placeholder-color);
  padding-left: 15px;
}
p-multiselect .new-version-multiselect:not(.p-disabled):hover {
  border-color: var(--border-focus-color);
}
p-multiselect .new-version-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}
p-multiselect .new-version-multiselect:not(.p-disabled).p-focus, p-multiselect .new-version-multiselect.p-multiselect-open {
  border-color: var(--border-focus-color);
}
p-multiselect .new-version-multiselect:not(.p-disabled).p-focus:hover, p-multiselect .new-version-multiselect.p-multiselect-open:hover {
  border-color: var(--border-focus-color);
}
p-multiselect .new-version-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
  font-size: var(--sm-fontsize);
  height: 15px;
}
p-multiselect .new-version-multiselect.p-multiselect-chip .p-multiselect-label {
  display: flex;
  padding-left: 15px;
  gap: 4px;
}
p-multiselect .new-version-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 1px 12px;
  background: var(--light-color-2);
  color: var(--base-color);
  border: 2px solid var(--border-color);
  border-radius: var(--border-radius);
  max-width: 100%;
  display: flex;
  flex: inherit;
  min-width: 0;
}
p-multiselect .new-version-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-label {
  min-width: 0;
  flex: 1;
  max-width: 100%;
}
p-multiselect .new-version-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  font-size: 10px;
  margin-left: 5px;
}
p-multiselect .new-version-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon .icon-remove:before {
  color: var(--light-color);
}
p-multiselect .new-version-multiselect .p-multiselect-clear-icon {
  width: 12px;
  height: 12px;
  color: var(--light-color);
  margin-top: -0.5rem;
}
p-multiselect.ng-invalid.ng-touched .new-version-multiselect {
  border-color: var(--danger-color) !important;
}
p-multiselect.blank-dropdown .p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items p-multiselectitem:last-child {
  border-top: 1px solid var(--border-color);
  display: block;
  padding-top: 8px;
}
p-multiselect.blank-dropdown .p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items p-multiselectitem:nth-last-child(2) {
  margin-bottom: 8px;
  display: block;
}

.p-multiselect-panel {
  box-shadow: none;
  overflow: hidden;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}
.p-multiselect-panel .p-multiselect-header {
  border-top-right-radius: var(--lg-border-radius);
  border-top-left-radius: var(--lg-border-radius);
  padding: 6px 16px;
  border-bottom: 0;
  background-color: transparent;
}
.p-multiselect-panel .p-multiselect-header .select-txt {
  color: var(--light-color);
  order: 3;
  flex: 1;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  display: none;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  order: 1;
  width: 100%;
  margin-bottom: 8px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  left: 16px;
  margin-top: -0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container input {
  padding: 5px 16px 5px 35px;
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items {
  padding: 0;
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-empty-message {
  padding: 6px 16px;
  color: var(--light-color);
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item {
  color: var(--base-color);
  padding: 6px 16px;
  white-space: normal;
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover, .p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item.p-highlight {
  background-color: var(--hover-color);
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
  background-color: var(--tab-focus-color);
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items-wrapper .p-multiselect-items .p-multiselect-item .p-multiselect-empty-message {
  padding: 6px 16px;
  color: var(--light-color);
}
.p-multiselect-panel .p-checkbox {
  margin-right: 8px;
}
.p-multiselect-panel .p-checkbox .p-checkbox-box {
  border: 1px solid #ced4da !important;
}

.viewMode .new-version-multiselect {
  border: 0;
  background-color: transparent;
  pointer-events: none;
  user-select: auto;
}
.viewMode .new-version-multiselect .p-multiselect-label-container {
  padding: 0;
}
.viewMode .new-version-multiselect .p-multiselect-label {
  white-space: unset;
  overflow: unset;
  text-overflow: unset;
  padding: 0;
}
.viewMode .new-version-multiselect .p-multiselect-clear-icon {
  display: none;
}
.viewMode .new-version-multiselect .p-multiselect-trigger .p-multiselect-trigger-icon {
  display: none;
}

.confirm-dialog .p-dialog-footer {
  margin-top: 20px;
}
.confirm-dialog .p-dialog-footer .p-button {
  border: none;
  box-shadow: none;
  outline: none;
}
.confirm-dialog .p-dialog-footer .p-button:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.confirm-dialog .p-dialog-footer .p-button .p-icon-wrapper {
  display: none;
}
.confirm-dialog .p-dialog-footer .p-button .p-button-label {
  font-weight: 400;
  font-size: 16px;
}

.p-float-label label {
  top: 32%;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: var(--base-fontsize);
}

.p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {
  top: -8px;
  font-size: var(--sm-fontsize);
}

.p-float-label :focus ~ label, .p-float-label .p-filled ~ label {
  top: -8px;
  font-size: var(--sm-fontsize);
}

p-radiobutton .p-radiobutton, p-tableradiobutton .p-radiobutton {
  display: flex;
  align-items: center;
}
p-radiobutton .p-radiobutton .p-radiobutton-box, p-tableradiobutton .p-radiobutton .p-radiobutton-box {
  background: url("../../../assets/radio-button-default.svg");
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}
p-radiobutton .p-radiobutton .p-radiobutton-box.p-highlight, p-tableradiobutton .p-radiobutton .p-radiobutton-box.p-highlight {
  background: url("../../../assets/radio-button-highlight.svg");
}
p-radiobutton .p-radiobutton .p-radiobutton-box .p-radiobutton-icon, p-tableradiobutton .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  display: none;
}

.color-picker-select {
  gap: 8px;
  display: flex;
}
.color-picker-select .p-button {
  border-radius: 4px;
}
.color-picker-select .p-button .item {
  width: 32px;
  height: 32px;
}
.color-picker-select .p-button.p-highlight {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #9dc1fb, 0 1px 2px 0 black;
}

.p-inputswitch .p-inputswitch-slider {
  background-color: var(--primary);
  box-shadow: none;
  outline: none;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #444661;
}

.p-cardAccordion {
  display: block;
}
.p-cardAccordion .p-card {
  border: 1px solid rgb(156, 156, 156);
  box-shadow: none;
  border-radius: 30px;
  color: var(--base-color);
}
.voyage-list .p-cardAccordion .p-card {
  height: 100%;
}
.p-cardAccordion .p-card .p-card-title {
  font-weight: 700;
  font-size: var(--lg-fontsize);
  margin-bottom: var(--md-space);
}
.p-cardAccordion .p-card .p-card-header {
  padding: var(--md-space);
}

p-sidebar .p-sidebar {
  background: rgb(247, 247, 247);
}
p-sidebar .p-sidebar-header {
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
}
p-sidebar .p-sidebar-content {
  padding: 16px;
}
p-sidebar .p-sidebar-footer {
  border-top: 1px solid var(--border-color);
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #1b1e42;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #888cc1;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #1b1e42;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #1b1e42;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #888cc1;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #888cc1;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #888cc1;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #888cc1;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 16px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 16px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-card {
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 30px;
  --mat-card-title-text-size: 30px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 600;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 16px;
  --mat-card-subtitle-text-size: 16px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 600;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #1b1e42;
  --mdc-linear-progress-track-color: rgba(27, 30, 66, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(27, 30, 66, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(27, 30, 66, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(27, 30, 66, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(27, 30, 66, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(27, 30, 66, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #888cc1;
  --mdc-linear-progress-track-color: rgba(136, 140, 193, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(136, 140, 193, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(136, 140, 193, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(136, 140, 193, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(136, 140, 193, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(136, 140, 193, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 16px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-caret-color: #1b1e42;
  --mdc-filled-text-field-focus-active-indicator-color: #1b1e42;
  --mdc-filled-text-field-focus-label-text-color: rgba(27, 30, 66, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #1b1e42;
  --mdc-outlined-text-field-focus-outline-color: #1b1e42;
  --mdc-outlined-text-field-focus-label-text-color: rgba(27, 30, 66, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(27, 30, 66, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(136, 140, 193, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #888cc1;
  --mdc-filled-text-field-focus-active-indicator-color: #888cc1;
  --mdc-filled-text-field-focus-label-text-color: rgba(136, 140, 193, 0.87);
  --mdc-outlined-text-field-caret-color: #888cc1;
  --mdc-outlined-text-field-focus-outline-color: #888cc1;
  --mdc-outlined-text-field-focus-label-text-color: rgba(136, 140, 193, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 16px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 18px;
  --mat-form-field-subscript-text-size: 16px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(27, 30, 66, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(136, 140, 193, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 16px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 30px;
  --mdc-dialog-subhead-size: 30px;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 16px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1b1e42;
  --mdc-chip-elevated-disabled-container-color: #1b1e42;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #888cc1;
  --mdc-chip-elevated-disabled-container-color: #888cc1;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 16px;
  --mdc-chip-label-text-size: 16px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #17193a;
  --mdc-switch-selected-handle-color: #17193a;
  --mdc-switch-selected-hover-state-layer-color: #17193a;
  --mdc-switch-selected-pressed-state-layer-color: #17193a;
  --mdc-switch-selected-focus-handle-color: black;
  --mdc-switch-selected-hover-handle-color: black;
  --mdc-switch-selected-pressed-handle-color: black;
  --mdc-switch-selected-focus-track-color: #41435a;
  --mdc-switch-selected-hover-track-color: #41435a;
  --mdc-switch-selected-pressed-track-color: #41435a;
  --mdc-switch-selected-track-color: #41435a;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #17193a;
  --mdc-switch-selected-handle-color: #17193a;
  --mdc-switch-selected-hover-state-layer-color: #17193a;
  --mdc-switch-selected-pressed-state-layer-color: #17193a;
  --mdc-switch-selected-focus-handle-color: black;
  --mdc-switch-selected-hover-handle-color: black;
  --mdc-switch-selected-pressed-handle-color: black;
  --mdc-switch-selected-focus-track-color: #41435a;
  --mdc-switch-selected-hover-track-color: #41435a;
  --mdc-switch-selected-pressed-track-color: #41435a;
  --mdc-switch-selected-track-color: #41435a;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-font: Roboto, sans-serif;
  --mat-slide-toggle-label-text-size: 16px;
  --mat-slide-toggle-label-text-tracking: normal;
  --mat-slide-toggle-label-text-line-height: 16px;
  --mat-slide-toggle-label-text-weight: 400;
}
.mat-mdc-slide-toggle .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #1b1e42;
  --mdc-radio-selected-hover-icon-color: #1b1e42;
  --mdc-radio-selected-icon-color: #1b1e42;
  --mdc-radio-selected-pressed-icon-color: #1b1e42;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #1b1e42;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #888cc1;
  --mdc-radio-selected-hover-icon-color: #888cc1;
  --mdc-radio-selected-icon-color: #888cc1;
  --mdc-radio-selected-pressed-icon-color: #888cc1;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #888cc1;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 16px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #1b1e42;
  --mdc-slider-focus-handle-color: #1b1e42;
  --mdc-slider-hover-handle-color: #1b1e42;
  --mdc-slider-active-track-color: #1b1e42;
  --mdc-slider-inactive-track-color: #1b1e42;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #1b1e42;
  --mat-mdc-slider-ripple-color: #1b1e42;
  --mat-mdc-slider-hover-ripple-color: rgba(27, 30, 66, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(27, 30, 66, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #888cc1;
  --mdc-slider-focus-handle-color: #888cc1;
  --mdc-slider-hover-handle-color: #888cc1;
  --mdc-slider-active-track-color: #888cc1;
  --mdc-slider-inactive-track-color: #888cc1;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #888cc1;
  --mat-mdc-slider-ripple-color: #888cc1;
  --mat-mdc-slider-hover-ripple-color: rgba(136, 140, 193, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(136, 140, 193, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 16px;
  --mdc-slider-label-label-text-line-height: 16px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 600;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

html {
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 16px;
  --mat-menu-item-label-text-weight: 400;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #1b1e42;
  --mdc-radio-selected-hover-icon-color: #1b1e42;
  --mdc-radio-selected-icon-color: #1b1e42;
  --mdc-radio-selected-pressed-icon-color: #1b1e42;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #888cc1;
  --mdc-radio-selected-hover-icon-color: #888cc1;
  --mdc-radio-selected-icon-color: #888cc1;
  --mdc-radio-selected-pressed-icon-color: #888cc1;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1b1e42;
  --mdc-checkbox-selected-hover-icon-color: #1b1e42;
  --mdc-checkbox-selected-icon-color: #1b1e42;
  --mdc-checkbox-selected-pressed-icon-color: #1b1e42;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1b1e42;
  --mdc-checkbox-selected-hover-state-layer-color: #1b1e42;
  --mdc-checkbox-selected-pressed-state-layer-color: #1b1e42;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #888cc1;
  --mdc-checkbox-selected-hover-icon-color: #888cc1;
  --mdc-checkbox-selected-icon-color: #888cc1;
  --mdc-checkbox-selected-pressed-icon-color: #888cc1;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #888cc1;
  --mdc-checkbox-selected-hover-state-layer-color: #888cc1;
  --mdc-checkbox-selected-pressed-state-layer-color: #888cc1;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #1b1e42;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #1b1e42;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 16px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 16px;
  --mdc-list-list-item-supporting-text-size: 16px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 18px;
  --mdc-list-list-item-trailing-supporting-text-size: 16px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  font-family: Roboto, sans-serif;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 18px;
  --mat-paginator-container-text-size: 16px;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 16px;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #1b1e42;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #1b1e42;
  --mat-tab-header-active-ripple-color: #1b1e42;
  --mat-tab-header-inactive-ripple-color: #1b1e42;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1b1e42;
  --mat-tab-header-active-hover-label-text-color: #1b1e42;
  --mat-tab-header-active-focus-indicator-color: #1b1e42;
  --mat-tab-header-active-hover-indicator-color: #1b1e42;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #888cc1;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #888cc1;
  --mat-tab-header-active-ripple-color: #888cc1;
  --mat-tab-header-inactive-ripple-color: #888cc1;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #888cc1;
  --mat-tab-header-active-hover-label-text-color: #888cc1;
  --mat-tab-header-active-focus-indicator-color: #888cc1;
  --mat-tab-header-active-hover-indicator-color: #888cc1;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #1b1e42;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #888cc1;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 16px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 16px;
  --mat-tab-header-label-text-weight: 400;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #888cc1;
  --mdc-checkbox-selected-hover-icon-color: #888cc1;
  --mdc-checkbox-selected-icon-color: #888cc1;
  --mdc-checkbox-selected-pressed-icon-color: #888cc1;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #888cc1;
  --mdc-checkbox-selected-hover-state-layer-color: #888cc1;
  --mdc-checkbox-selected-pressed-state-layer-color: #888cc1;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #1b1e42;
  --mdc-checkbox-selected-hover-icon-color: #1b1e42;
  --mdc-checkbox-selected-icon-color: #1b1e42;
  --mdc-checkbox-selected-pressed-icon-color: #1b1e42;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1b1e42;
  --mdc-checkbox-selected-hover-state-layer-color: #1b1e42;
  --mdc-checkbox-selected-pressed-state-layer-color: #1b1e42;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-checkbox .mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-body2-font-size, 16px);
  line-height: var(--mdc-typography-body2-line-height, 16px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #1b1e42;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #888cc1;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #1b1e42;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #888cc1;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #1b1e42;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #888cc1;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #1b1e42;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #888cc1;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #1b1e42;
  --mat-mdc-button-ripple-color: rgba(27, 30, 66, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #888cc1;
  --mat-mdc-button-ripple-color: rgba(136, 140, 193, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 16px);
  line-height: var(--mdc-typography-button-line-height, 16px);
  font-weight: var(--mdc-typography-button-font-weight, 400);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #1b1e42;
  --mat-mdc-button-persistent-ripple-color: #1b1e42;
  --mat-mdc-button-ripple-color: rgba(27, 30, 66, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #888cc1;
  --mat-mdc-button-persistent-ripple-color: #888cc1;
  --mat-mdc-button-ripple-color: rgba(136, 140, 193, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #1b1e42;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #888cc1;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: var(--mdc-typography-button-font-size, 16px);
  line-height: var(--mdc-typography-button-line-height, 16px);
  font-weight: var(--mdc-typography-button-font-weight, 400);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-extended-fab {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 16px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 400;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #888cc1;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 16px;
  --mdc-snackbar-supporting-text-size: 16px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 16px;
  --mat-table-header-headline-size: 16px;
  --mat-table-header-headline-weight: 600;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 16px;
  --mat-table-row-item-label-text-size: 16px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 16px;
  --mat-table-footer-supporting-text-size: 16px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #1b1e42;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #888cc1;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #1b1e42;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #888cc1;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 16px;
  --mat-bottom-sheet-container-text-size: 16px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-text-font: Roboto, sans-serif;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1b1e42;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(27, 30, 66, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(27, 30, 66, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(27, 30, 66, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #1b1e42;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(27, 30, 66, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #888cc1;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(136, 140, 193, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(136, 140, 193, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(136, 140, 193, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(136, 140, 193, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #888cc1;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 16px;
  --mat-datepicker-calendar-body-label-text-weight: 400;
  --mat-datepicker-calendar-period-button-text-size: 16px;
  --mat-datepicker-calendar-period-button-text-weight: 400;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 16px;
  --mat-expansion-header-text-weight: 600;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 16px;
  --mat-expansion-container-text-size: 16px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 16px;
  --mat-grid-list-tile-header-secondary-text-size: 16px;
  --mat-grid-list-tile-footer-primary-text-size: 16px;
  --mat-grid-list-tile-footer-secondary-text-size: 16px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #1b1e42;
}
.mat-icon.mat-accent {
  --mat-icon-color: #888cc1;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1b1e42;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1b1e42;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1b1e42;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #888cc1;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #888cc1;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #888cc1;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 16px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #1b1e42;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #888cc1;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 30px;
  --mat-toolbar-title-text-size: 30px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 600;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: Roboto, sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 16px;
}

/* You can add global styles to this file, and also import other style files */
:root {
  --theme-primary: #1b1e42;
  --theme-accent: #1b1e42;
  --theme-warn: #f44336;
  --theme-white: white;
  --theme-white-secondary: #f8f9f9;
  --theme-white-tertiary: #dee5e5;
  --theme-black: black;
  --theme-secondary: #888cc1;
  --theme-green: #00c853;
  --theme-yellow: #ffd600;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Segoe UI", sans-serif;
  background-color: var(--theme-white-secondary);
  overflow: hidden;
}
body .mdc-button.mat-warn,
body .mat-mdc-stroked-button.mat-success {
  color: #D6002A;
}
body .mat-mdc-unelevated-button.mat-warn:hover,
body .mat-mdc-raised-button.mat-warn:hover {
  color: #f0fff3;
  background-color: #D6002A;
}
body .mat-mdc-unelevated-button:not(:disabled, :hover).mat-success,
body .mat-mdc-raised-button.mat-warn,
body .mat-mdc-fab.mat-warn,
body .mat-mdc-mini-fab.mat-wanr {
  color: #f0fff3;
  background-color: #D6002A;
}
body .mat-mdc-icon-button.mat-warn {
  color: #D6002A;
}
body .mdc-button.mat-warn:disabled,
body .mat-mdc-stroked-button.mat-success:disabled,
body .mat-mdc-unelevated-button.mat-warn:disabled,
body .mat-mdc-raised-button.mat-warn:disabled,
body .mat-mdc-fab.mat-warn:disabled,
body .mat-mdc-mini-fab.mat-warn:disabled,
body .mat-mdc-icon-button.mat-warn:disabled {
  color: rgba(0, 0, 0, 0.38);
  background-color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
  pointer-events: none;
}

.mat-sidenav-container .mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent !important;
}

.mat-sidenav-container {
  height: calc(100vh - 64px);
}

.mat-sidenav,
.mat-sidenav-content {
  transition: all 300ms ease-in-out;
}

.mat-drawer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

lha-landing-page .mdc-data-table__header-cell {
  color: var(--theme-primary);
}
lha-landing-page .mdc-data-table__cell a:not(.mdc-icon-button) {
  text-decoration: none;
  color: var(--theme-warn);
  font-weight: 500;
}
lha-landing-page .mdc-data-table__cell a:not(.mdc-icon-button):hover {
  text-decoration: underline;
}
lha-landing-page .mat-elevation-z8,
lha-landing-page .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: none;
}
lha-landing-page .mat-drawer-content {
  display: flex;
  flex-direction: column;
}
lha-landing-page .mat-mdc-table .mdc-data-table__row {
  height: 45px;
}
lha-landing-page .mat-mdc-table .mdc-data-table__header-row {
  height: 45px;
}
lha-landing-page .mat-toolbar {
  background: var(--theme-white);
}

lha-deck-usage .mat-mdc-table .mdc-data-table__row {
  height: 35px;
}
lha-deck-usage .mat-mdc-icon-button.mat-mdc-button-base {
  width: 35px;
  height: 35px;
  padding: 5px;
}

lha-footer,
lha-header {
  width: 100%;
}

.as-end {
  align-self: flex-end;
  width: 100%;
  overflow: hidden;
}

.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
  flex-grow: 0 !important;
}

h3.mat-title--warn {
  color: var(--theme-warn);
}

.left_section {
  display: flex;
  align-items: center;
}

.form__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form__box {
  width: 49%;
  margin-bottom: 10px;
}
.form__box--mb15 {
  margin-bottom: 15px;
}
.form__box--mb20 {
  margin-bottom: 20px;
}

.table__header_right button,
.table__header_right .mdc-button,
.table__header_right lha-date-range,
.voyage_flat__header_right button,
.voyage_flat__header_right .mdc-button,
.voyage_flat__header_right lha-date-range {
  margin: 5px 0 5px 15px;
}

.mat-icon--accept {
  color: var(--theme-green);
}
.mat-icon--cancel {
  color: var(--theme-warn);
}

mat-error {
  font-size: 11px;
}

mat-chip-option[disabled] {
  background-color: #e4e7eb !important;
  opacity: 1 !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label {
  color: var(--theme-primary) !important;
}

.errors_dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  background: none;
}

.import_btn {
  position: relative;
}
.import_btn input {
  opacity: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: absolute;
  pointer-events: none;
}

.label-color {
  color: var(--label-color);
}

.base-color {
  color: var(--base-color);
}

.time_box {
  align-items: center;
  display: flex;
  font-size: 16px;
}
.time_box__title {
  margin-right: 5px;
  font-weight: bold;
}
.time_box__text {
  color: #4f6566;
  font-weight: normal;
}

.billing_voyage__head {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.billing_voyage__info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.billing_voyage__box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.billing_voyage__box--4 lha-billing-voyage-card {
  width: 24%;
}
.billing_voyage__box--3 lha-billing-voyage-card {
  width: 32%;
}

.mat-icon--spinner {
  width: 25px !important;
  height: 25px !important;
  position: absolute !important;
  left: 50%;
  top: 50%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
}

.disable-label {
  position: absolute;
  left: 0;
  top: calc(100% + 3px);
  font-size: 12px;
  line-height: 1;
  color: var(--theme-primary);
}
.disable-label--alone {
  top: 78%;
  color: var(--theme-warn);
}
.disable-label--single {
  top: 78%;
}

.recalculate-icon {
  display: inline-block;
  background: var(--theme-warn);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 0 -1px 10px;
}

.mat-mdc-list-base {
  height: 100%;
  display: flex !important;
  flex-direction: column;
}

.currency-prefix {
  display: inline-block;
  margin-left: 10px;
  padding: 0 5px;
  font-weight: bold;
  font-size: 20px;
  color: var(--theme-primary);
}

.input-prefix {
  display: inline-block;
  margin-left: 15px;
}

.no-wrap {
  white-space: nowrap;
}

.issue-mat-icon {
  margin-left: 10px;
}

.not-valid-icon {
  position: relative;
  display: inline-block;
  border: 2px solid var(--theme-warn);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 10px -4px;
}
.not-valid-icon:before {
  content: "!";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: var(--theme-warn);
}

.hire_statement__right .table__header_box {
  margin: 5px 0;
}

.image_dialog {
  aspect-ratio: 16/9;
  min-width: 80vw;
}
.image_dialog .mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow: none;
}

.dialog_info_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog_info {
  width: 35px;
  height: 35px;
  border: 2px solid var(--theme-primary);
  border-radius: 50%;
  line-height: 35px;
  cursor: pointer;
  position: relative;
}
.dialog_info__text {
  text-align: center;
  font-size: 20px;
  color: var(--theme-primary);
}
.dialog_info:hover .custom-tooltip {
  opacity: 1;
  visibility: visible;
}

.not_valid {
  position: relative;
}
.not_valid:hover .custom-tooltip {
  opacity: 1;
  visibility: visible;
}

.custom-tooltip {
  position: absolute;
  padding: 6px;
  border-radius: 5px;
  right: 120%;
  top: 50%;
  transform: translateY(-60%);
  color: #ffffff;
  background: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  transition: 0.3s all ease;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
}
.custom-tooltip--right {
  right: auto;
  left: 110%;
  transform: translateY(-50%);
}

.dialog-container {
  height: 100%;
}
.dialog-container .page_container__content {
  height: 100%;
}

.mat-datepicker-content {
  background: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-calendar-body-cell-content {
  border: none;
}
.mat-datepicker-content .mat-calendar-body-cell-content:hover {
  background: grey;
}
.mat-datepicker-content .mat-calendar-body-cell-content.mat-calendar-body-selected {
  background: #1b1e42;
  color: #fff;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

@media (max-width: 1600px) {
  .hire_statement__right .table__header_container {
    flex-wrap: wrap;
  }
}
@media (max-width: 1100px) {
  .mat-toolbar-row,
  .mat-toolbar-single-row {
    height: auto;
  }
  lha-bulk-transaction .table__header_container {
    align-items: flex-start !important;
  }
  .mat-toolbar h1 {
    font-size: 26px;
    white-space: break-spaces;
  }
  .billing_voyage__head {
    margin-bottom: 10px;
  }
  .billing_voyage__info {
    margin-bottom: 10px;
  }
  .billing_voyage__box {
    margin-bottom: 10px;
    flex-wrap: wrap;
    row-gap: 5px;
  }
  .billing_voyage__box--4 lha-billing-voyage-card {
    width: 49%;
  }
  .billing_voyage__box--3 lha-billing-voyage-card {
    width: 49%;
  }
  .billing_voyage__box--3 lha-billing-voyage-card:last-child {
    width: 100%;
  }
}
@media (max-width: 900px) {
  .mdc-data-table__cell {
    white-space: nowrap;
  }
}
.warning-message-container {
  padding: 10px;
  border: 1px solid #1C1E41;
  border-radius: 5px;
}

.location-list {
  margin: 0 auto;
  color: #00729e;
}

.home-url {
  color: #00729e;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}