/* You can add global styles to this file, and also import other style files */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '_app-palette';
@use '@angular/material' as mat;
@import '@angular/material/theming';
@import './component-styles/index';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import './variables';
@import './mixins';
@import './utilities/index.scss';
@import '../prime-ng/index.scss';
@import "primeicons/primeicons.css";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$lighthouse-web-primary: mat.define-palette(app-palette.$lighthouse-palette);
$lighthouse-web-accent: mat.define-palette(
    app-palette.$lighthouse-palette,
    A100,
    A200,
    A400
);

// The warn palette is optional (defaults to red).
$lighthouse-web-warn: mat.define-palette(mat.$red-palette);
$lighthouse-web-green: mat.define-palette(mat.$green-palette);
$lighthouse-web-yellow: mat.define-palette(mat.$yellow-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$lighthouse-web-theme: mat.define-light-theme(
    (
      color: (
        primary: $lighthouse-web-primary,
        accent: $lighthouse-web-accent,
        warn: $lighthouse-web-warn,
      ),
      typography:
      mat.define-typography-config(
        $headline-5: mat.define-typography-level(30px),
        $headline-6: mat.define-typography-level(30px, 30px, 600),
        $body-1: mat.define-typography-level(16px),
        $body-2: mat.define-typography-level(16px),
        $subtitle-1: mat.define-typography-level(18px),
        $subtitle-2: mat.define-typography-level(16px, 16px, 600),
        $button: mat.define-typography-level(16px),
        $caption: mat.define-typography-level(16px, 18px),
      ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($lighthouse-web-theme);

/* You can add global styles to this file, and also import other style files */

$primary-pallette: mat-palette($lighthouse-web-primary);
$accent-pallette: mat-palette($lighthouse-web-accent);
$warn-pallette: mat-palette($lighthouse-web-warn);
$green-pallette: mat-palette($lighthouse-web-green);
$yellow-pallette: mat-palette($lighthouse-web-yellow);

$primary: mat-color($primary-pallette);
$accent: mat-color($accent-pallette);
$warn: mat-color($warn-pallette);
$green: mat-color($green-pallette);
$yellow: mat-color($yellow-pallette);

$primary-50: mat-color($primary-pallette, 50);
$primary-100: mat-color($primary-pallette, 100);
$primary-200: mat-color($primary-pallette, 200);
$primary-900: mat-color($primary-pallette, 900);
$primary-A100: mat-color($primary-pallette, A100);
$green-A700: mat-color($green-pallette, A700);
$yellow-A700: mat-color($yellow-pallette, A700);

:root {
  --theme-primary: #{$primary};
  --theme-accent: #{$accent};
  --theme-warn: #{$warn};
  --theme-white: #{$primary-50};
  --theme-white-secondary: #{$primary-100};
  --theme-white-tertiary: #{$primary-200};
  --theme-black: #{$primary-900};
  --theme-secondary: #{$primary-A100};
  --theme-green: #{$green-A700};
  --theme-yellow: #{$yellow-A700};
}
* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Segoe UI', sans-serif;
  background-color: var(--theme-white-secondary);
  overflow: hidden;
  // to prevent messing around with themes for material buttons
  .mdc-button.mat-warn,
    .mat-mdc-stroked-button.mat-success {
      color: #D6002A;
    }
    .mat-mdc-unelevated-button.mat-warn:hover,
    .mat-mdc-raised-button.mat-warn:hover {
      color: #f0fff3;
      background-color: #D6002A;
    }

    .mat-mdc-unelevated-button:not(:disabled, :hover).mat-success,
    .mat-mdc-raised-button.mat-warn,
    .mat-mdc-fab.mat-warn,
    .mat-mdc-mini-fab.mat-wanr {
      color: #f0fff3;
      background-color: #D6002A;
    }

    .mat-mdc-icon-button.mat-warn {
      color: #D6002A;
    }
    .mdc-button.mat-warn:disabled,
    .mat-mdc-stroked-button.mat-success:disabled,
    .mat-mdc-unelevated-button.mat-warn:disabled,
    .mat-mdc-raised-button.mat-warn:disabled,
    .mat-mdc-fab.mat-warn:disabled,
    .mat-mdc-mini-fab.mat-warn:disabled,
    .mat-mdc-icon-button.mat-warn:disabled {
      color: rgba(0, 0, 0, 0.38);
      background-color: rgba(0, 0, 0, 0.12);
      cursor: not-allowed;
      pointer-events: none;
    }
}

.mat-sidenav-container .mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent !important;
}

.mat-sidenav-container {
  height: calc(100vh - 64px);
}

.mat-sidenav,
.mat-sidenav-content{
  transition: all 300ms ease-in-out;
}

.mat-drawer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

lha-landing-page {
  .mdc-data-table__header-cell {
    color: var(--theme-primary);
  }
  .mdc-data-table__cell {
    a:not(.mdc-icon-button) {
      text-decoration: none;
      color: var(--theme-warn);
      font-weight: 500;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .mat-elevation-z8,
  .mat-mdc-elevation-specific.mat-elevation-z8 {
    box-shadow: none;
  }
  .mat-drawer-content {
    display: flex;
    flex-direction: column;
  }
  .mat-mdc-table .mdc-data-table__row {
    height: 45px;
  }
  .mat-mdc-table .mdc-data-table__header-row {
    height: 45px;
  }
  .mat-toolbar {
    background: var(--theme-white);
  }
}

lha-deck-usage {
  .mat-mdc-table .mdc-data-table__row {
    height: 35px;
  }
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 35px;
    height: 35px;
    padding: 5px;
  }
}
lha-footer,
lha-header {
  width: 100%;
}
.as-end {
  align-self: flex-end;
  width: 100%;
  overflow: hidden;
}
.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
  flex-grow: 0 !important;
}
h3.mat-title--warn {
  color: var(--theme-warn);
}
.left_section {
  display: flex;
  align-items: center;
}
.form__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form__box {
  width: 49%;
  margin-bottom: 10px;
  &--mb15 {
    margin-bottom: 15px;
  }
  &--mb20 {
    margin-bottom: 20px;
  }
}

.table__header_right,
.voyage_flat__header_right {
  button,
  .mdc-button,
  lha-date-range {
    margin: 5px 0 5px 15px;
  }
}

.mat-icon {
  &--accept {
    color: var(--theme-green);
  }
  &--cancel {
    color: var(--theme-warn);
  }
}

mat-error {
  font-size: 11px;
}

mat-chip-option[disabled] {
  background-color: #e4e7eb !important;
  opacity: 1 !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--disabled
.mdc-evolution-chip__text-label {
  color: var(--theme-primary) !important;
}

.errors_dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: none;
  }
}

.import_btn {
  position: relative;
  input {
    opacity: 0;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: absolute;
    pointer-events: none;
  }
}

.label-color {
  color: var(--label-color);
}

.base-color {
  color: var(--base-color);
}

.time_box {
  align-items: center;
  display: flex;
  font-size: 16px;
  &__title {
    margin-right: 5px;
    font-weight: bold;
  }
  &__text {
    color: #4f6566;
    font-weight: normal;
  }
}
.billing_voyage {
  &__head {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  &__box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    &--4 {
      lha-billing-voyage-card {
        width: 24%;
      }
    }
    &--3 {
      lha-billing-voyage-card {
        width: 32%;
      }
    }
  }
}
.mat-icon--spinner {
  width: 25px !important;
  height: 25px !important;
  position: absolute !important;
  left: 50%;
  top: 50%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
}
.disable-label {
  position: absolute;
  left: 0;
  top: calc(100% + 3px);
  font-size: 12px;
  line-height: 1;
  color: var(--theme-primary);
  &--alone {
    top: 78%;
    color: var(--theme-warn);
  }
  &--single {
    top: 78%;
  }
}
.recalculate-icon {
  display: inline-block;
  background: var(--theme-warn);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 0 -1px 10px;
}
.mat-mdc-list-base {
  height: 100%;
  display: flex !important;
  flex-direction: column;
}
.currency-prefix {
  display: inline-block;
  margin-left: 10px;
  padding: 0 5px;
  font-weight: bold;
  font-size: 20px;
  color: var(--theme-primary);
}
.input-prefix {
  display: inline-block;
  margin-left: 15px;
}
.no-wrap {
  white-space: nowrap;
}
.issue-mat-icon {
  margin-left: 10px;
}
.not-valid-icon {
  position: relative;
  display: inline-block;
  border: 2px solid var(--theme-warn);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 10px -4px;
  &:before {
    content: '!';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: var(--theme-warn);
  }
}
.hire_statement__right {
  .table__header_box {
    margin: 5px 0;
  }
}
.image_dialog {
  aspect-ratio: 16/9;
  min-width: 80vw;
  .mat-mdc-dialog-container .mdc-dialog__surface {
    box-shadow: none;
  }
}
.dialog_info_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialog_info {
  width: 35px;
  height: 35px;
  border: 2px solid var(--theme-primary);
  border-radius: 50%;
  line-height: 35px;
  cursor: pointer;
  position: relative;
  &__text {
    text-align: center;
    font-size: 20px;
    color: var(--theme-primary);
  }
  &:hover {
    .custom-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
}
.not_valid {
  position: relative;
  &:hover {
    .custom-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
}
.custom-tooltip {
  position: absolute;
  padding: 6px;
  border-radius: 5px;
  right: 120%;
  top: 50%;
  transform: translateY(-60%);
  color: #ffffff;
  background: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
  transition: 0.3s all ease;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  &--right {
    right: auto;
    left: 110%;
    transform: translateY(-50%);
  }
}

.dialog-container {
  height: 100%;
  .page_container__content {
    height: 100%;
  }
}

.mat-datepicker-content {
  background: white;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, .2), 0px 4px 5px 0px rgba(0, 0, 0, .14), 0px 1px 10px 0px rgba(0, 0, 0, .12); // angular's box shadow for datepicker

  // Removes the circles around the dates
  .mat-calendar-body-cell-content {
    border: none;

    // When date was hovered, this is what it will look like
    &:hover {
      background: grey;
    }

    // When date was selected, this is what it will look like
    &.mat-calendar-body-selected {
      background: #1b1e42;
      color: #fff;
    }
  }
}
.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

@media (max-width: 1600px) {
  .hire_statement__right {
    .table__header_container {
      flex-wrap: wrap;
    }
  }
}
@media (max-width: 1100px) {
  .mat-toolbar-row,
  .mat-toolbar-single-row {
    height: auto;
  }
  lha-bulk-transaction {
    .table__header_container {
      align-items: flex-start !important;
    }
  }
  .mat-toolbar {
    h1 {
      font-size: 26px;
      white-space: break-spaces;
    }
  }

  .billing_voyage {
    &__head {
      margin-bottom: 10px;
    }
    &__info {
      margin-bottom: 10px;
    }
    &__box {
      margin-bottom: 10px;
      flex-wrap: wrap;
      row-gap: 5px;
      &--4 {
        lha-billing-voyage-card {
          width: 49%;
        }
      }
      &--3 {
        lha-billing-voyage-card {
          width: 49%;
          &:last-child {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .mdc-data-table__cell {
    white-space: nowrap;
  }
}

.warning-message-container {
  padding: 10px;
  border: 1px solid #1C1E41;
  border-radius: 5px;
}

.location-list {
  margin: 0 auto;
  color: #00729e;
}

.home-url {
  color: #00729e;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
