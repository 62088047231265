.spinner-style {
  .p-progress-spinner-svg circle {
    stroke: #1c1e41 !important;
  }

  &.p-progress-spinner {
    position: relative;
    margin: 0 auto;
    display: inline-block;
  }
}

.small-spinner-style {
  .p-progress-spinner-svg circle {
    stroke: #1c1e41 !important;
  }

  &.p-progress-spinner {
    position: relative;
    margin: 0 auto;
    width: 30px;
    height: 30px;
    display: inline-block;
  }
}

.smaller-spinner-style {
  .p-progress-spinner-svg circle {
    stroke: #ffffff !important;
  }

  &.p-progress-spinner {
    position: relative;
    margin: 0 auto;
    width: 10px;
    height: 10px;
    display: inline-block;
  }
}
