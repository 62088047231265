p-dialog,
p-confirmdialog {
  .p-dialog {
    background-color: var(--white);

    .p-dialog-header {
      border-bottom: 1px solid var(--border-color);
      padding: 12px var(--lg-space);

      .header {
        font-weight: bold;
        font-size: 20px;
      }

      .p-dialog-header-icon {
        color: var(--base-color);
        width: unset;

        &:focus {
          box-shadow: none;
        }

        &:enabled:hover {
          background-color: transparent;
        }
      }
    }

    .p-dialog-content {
      padding: 12px var(--lg-space);
      align-items: flex-start;
      position: relative;
      color: var(--base-color);

      .p-confirm-dialog-message {
        font-weight: 500;
        margin-left: 0;
      }

      .p-confirm-dialog-icon {
        &.icon-error {
          margin-right: 9px;
          font-size: 21px;

          &:before {
            color: var(--primary-4);
          }
        }
      }

      .dialog-txt {
        display: flex;

        em {
          margin-right: 9px;
          font-size: 21px;

          &:before {
            color: var(--primary-4);
          }
        }
      }

      .viewMode {
        .p-inputtext {
          padding-left: 0;
        }
      }
    }

    .p-dialog-footer {
      border-top: 1px solid var(--border-color);
      padding: 12px 24px;

      button {
        ~button {
          margin-left: 16px;
        }

        margin-right: 0;
      }
    }
  }
}